import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../../styles/exclusive/theme';
import CloseButton from '../../../icon-buttons/CloseButton';
import LangSelectButton from '../../LangSelection/LangSelectButton';
import { SideBarMenuParentNode, SideMenuItems } from '../../types';
import MenuSideBarSubMenuToggleButton from './MenuSideBarSubMenuExpandButton';

interface CloseButtonSectionProps extends HTMLAttributes<HTMLDivElement> {
  hasLangSelectButton: boolean;
}

const CloseButtonSection = styled(
  ({ hasLangSelectButton, ...props }: CloseButtonSectionProps) => (
    <div {...props} />
  ),
)`
  display: flex;
  align-items: center;

  ${({ hasLangSelectButton }: CloseButtonSectionProps) => {
    if (hasLangSelectButton) {
      return css`
        justify-content: space-between;
      `;
    }

    return css`
      justify-content: flex-end;
    `;
  }}
`;

export type FlattenedSideMenuItems = Map<SideBarMenuParentNode, SideMenuItems>;

const GoBackButton = styled(MenuSideBarSubMenuToggleButton).attrs({
  textPosition: 'before',
})`
  ${({ theme }: { theme: Theme }) => css`
    color: ${theme.colors.Cultured};
    border: 3px solid ${theme.colors.Brandy};
    background-color: ${theme.colors.Brandy};
    transition: background-color 300ms ease-in-out;
    padding: 5px 20px;
    font-size: 2.5rem;
    cursor: pointer;

    & svg {
      fill: ${theme.colors.Cultured};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: transparent;
      }
    }
  `};
`;

export interface MenuSideBarHeaderProps {
  isLangMenuActive: boolean;
  toggleLangMenu: () => void;
  onClose: () => void;
  showBackButton: boolean;
  onBackButtonClick: () => void;
  hasLangSelectButton: boolean;
  sideMenuGoBackButtonLabel: string;
  testIdGenerator: (testId: string) => string;
}

const MenuSideBarHeader = ({
  isLangMenuActive,
  toggleLangMenu,
  onClose,
  showBackButton,
  onBackButtonClick,
  hasLangSelectButton,
  sideMenuGoBackButtonLabel,
  testIdGenerator,
}: MenuSideBarHeaderProps) => {
  return (
    <>
      <CloseButtonSection hasLangSelectButton={hasLangSelectButton}>
        {hasLangSelectButton && (
          <LangSelectButton
            active={isLangMenuActive}
            onClick={toggleLangMenu}
          />
        )}
        <CloseButton
          size={30}
          colorMode="secondary"
          onClick={onClose}
          data-testid={testIdGenerator('side-menu-close-button')}
        />
      </CloseButtonSection>
      {showBackButton && (
        <GoBackButton
          onClick={onBackButtonClick}
          data-testid={testIdGenerator('side-menu-back-button')}
        >
          {sideMenuGoBackButtonLabel}
        </GoBackButton>
      )}
    </>
  );
};

export default MenuSideBarHeader;
