import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBoolean, useMediaQuery } from 'usehooks-ts';
import {
  areURLPathAndQSTheSame,
  parseURLFromPath,
} from '../../../../utils/universal/data/url';
import { DEFAULT_PROVIDER_PROPS, menuContext } from './menuContext';
import { MenuContextProviderProps } from '../types';
import { MINIMUM_LARGE_SCREEN_SIZE } from '../constants';
import { extractLangAndAgnosticPath } from '../lang';

const { Provider } = menuContext;

export interface MenuProviderProps extends Partial<MenuContextProviderProps> {
  menuExpandButtonLabel: string;
  sideMenuGoBackButtonLabel: string;
  children?: ReactNode;
}

const MenuProvider = ({ children, ...props }: MenuProviderProps) => {
  const contextValueAdditions = { ...DEFAULT_PROVIDER_PROPS, ...props };
  const location = useLocation();
  const isLargeScreen = useMediaQuery(
    `(min-width: ${MINIMUM_LARGE_SCREEN_SIZE}px)`,
  );
  const {
    value: isLangMenuActive,
    toggle: toggleLangMenu,
    setFalse: disableLangMenu,
    setTrue: enableLangMenu,
  } = useBoolean();
  const { value: isMenuSidebarOpen, toggle: toggleMenuSidebarOpen } =
    useBoolean(false);
  const [menuSidebarWidth, setMenuSidebarWidth] = useState(0);
  const [
    mainContentFixedElementWidthOffset,
    setMainContentFixedElementWidthOffset,
  ] = useState(0);

  useEffect(() => {
    if (isLargeScreen) {
      setMainContentFixedElementWidthOffset(menuSidebarWidth);
    } else {
      setMainContentFixedElementWidthOffset(0);
    }
  }, [menuSidebarWidth, isLargeScreen]);

  useEffect(() => {
    const { lang } = extractLangAndAgnosticPath({
      url: location.pathname,
      langs: props.langs ?? {},
    });

    if (lang && props.setCurrentLang) {
      props.setCurrentLang({
        value: lang,
      });
    }
  }, [location, props.setCurrentLang]);

  return (
    <Provider
      value={{
        isMenuSidebarOpen,
        toggleMenuSidebarOpen,
        menuSidebarWidth,
        setMenuSidebarWidth,
        mainContentFixedElementWidthOffset,
        isLargeScreen,
        isMenuItemActive: (to: string) =>
          areURLPathAndQSTheSame(
            parseURLFromPath(to),
            parseURLFromPath(
              extractLangAndAgnosticPath({
                url: location.pathname + location.search,
                langs: props.langs ?? {},
              }).agnosticPath,
            ),
            ['page'],
          ),
        isLangMenuActive,
        toggleLangMenu,
        disableLangMenu,
        enableLangMenu,
        enableLangSelection: Boolean(
          Object.values(contextValueAdditions.langs).length,
        ),
        ...contextValueAdditions,
      }}
    >
      {children}
    </Provider>
  );
};

export default MenuProvider;
