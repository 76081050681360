import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLangTools } from '../../universal/Menu/lang';

const DefaultLocaleHandler = () => {
  const { prefix } = useLangTools();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(prefix());
  }, [prefix, navigate]);

  return null;
};

export default DefaultLocaleHandler;
