import { get, set, insert, del, has, Path } from 'object-path';

export type AvailableLocale = 'en-US' | 'fa';

export const EN_US_LOCALE = 'en-US';

export const FA_LOCALE = 'fa';

export interface CFDataOptions {
  locale: AvailableLocale;
}

export type CFDataOptionsInput = Partial<CFDataOptions>;

export const CF_DATA_DEFAULT_OPTIONS: CFDataOptions = {
  locale: 'en-US',
};

export default class CFData<Data extends object, SubType> {
  data: Data;
  options: CFDataOptions;

  constructor(data: Data, options: CFDataOptionsInput = {}) {
    this.data = data;
    this.options = { ...CF_DATA_DEFAULT_OPTIONS, ...options };
  }

  get<T>(path: Path): T {
    return get(this.data, path);
  }

  set<T>(path: Path, value: any, doNotReplace?: boolean): T {
    return set(this.data, path, value, doNotReplace);
  }

  insert(path: Path, value: any, at?: number) {
    return insert(this.data, path, value, at);
  }

  del(path: Path) {
    return del(this.data, path);
  }

  has(path: Path) {
    return has(this.data, path);
  }

  toJSON() {
    return this.data;
  }

  fa(): SubType {
    const CFDataConstructor = this.#currentConstructor();
    return new CFDataConstructor(this.data, {
      ...this.options,
      locale: FA_LOCALE,
    }) as SubType;
  }

  en(): SubType {
    const CFDataConstructor = this.#currentConstructor();
    return new CFDataConstructor(this.data, {
      ...this.options,
      locale: EN_US_LOCALE,
    }) as SubType;
  }

  #currentConstructor(): typeof CFData {
    return this.constructor as typeof CFData;
  }
}
