import React from 'react';
import MenuButton from '../../icon-buttons/MenuButton';
import useMenuContext from '../context/useMenuContext';

const MenuExpandButton = () => {
  const { toggleMenuSidebarOpen, testIdGenerator } = useMenuContext();
  return (
    <MenuButton
      data-testid={testIdGenerator('menu-sidebar-expand-button')}
      size={50}
      onClick={toggleMenuSidebarOpen}
    />
  );
};

export default MenuExpandButton;
