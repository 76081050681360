import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';

export interface RouterStoreState {
  location: Location;
}

const { pathname, search, hash } = globalThis.window?.location ?? {
  pathname: '/',
  search: '',
  hash: '',
};

const initialState: RouterStoreState = {
  location: {
    state: undefined,
    key: 'default',
    pathname,
    search,
    hash,
  },
};

const { reducer, actions } = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setLocation: (state: RouterStoreState, action: PayloadAction<Location>) => {
      state.location = action.payload;
    },
  },
});

export const routerReducer = reducer;

export const { setLocation } = actions;
