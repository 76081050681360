import {
  ContentfulAsset,
  DefaultFields,
  ExtendedEntry,
} from '../../exclusive/types';
import CFAsset from './CFAsset';
import CFData from './CFData';
import CFList from './CFList';

export default class CFEntry<
  Fields extends DefaultFields = DefaultFields,
> extends CFData<ExtendedEntry<string, DefaultFields>, CFEntry<Fields>> {
  fields<F = Fields>(allLocales: boolean = false): F {
    if (allLocales) {
      return this.data.fields as unknown as F;
    }
    return Object.fromEntries(
      Object.entries(this.data.fields).map(([key, value]) => [
        key,
        typeof value[this.options.locale] !== 'undefined'
          ? value[this.options.locale]
          : value['en-US'],
      ]),
    ) as F;
  }

  field<Field>(fieldName: string, allLocales: boolean = false): Field {
    const path = ['fields', fieldName];

    if (!allLocales) {
      path.push(this.options.locale);
    }

    let value = this.get(path);

    if (typeof value === 'undefined' && this.options.locale !== 'en-US') {
      path.pop();
      path.push('en-US');
      value = this.get(path);
    }

    return value as Field;
  }

  entry<Fields extends DefaultFields>(fieldName: string) {
    return new CFEntry<Fields>(
      this.field<ExtendedEntry<string, DefaultFields>>(fieldName),
      this.options,
    );
  }

  entries<Fields extends DefaultFields>(
    fieldName: string,
  ): CFList<CFEntry<Fields>> {
    return new CFList<CFEntry<Fields>>(
      (this.field<ExtendedEntry<string, DefaultFields>[]>(fieldName) ?? []).map(
        (entry) => new CFEntry(entry, this.options),
      ),
      this.options,
    );
  }

  asset(fieldName: string) {
    return new CFAsset(this.field<ContentfulAsset>(fieldName), this.options);
  }

  assets(fieldName: string) {
    return new CFList<CFAsset>(
      (this.field<ContentfulAsset[]>(fieldName) ?? []).map(
        (entry) => new CFAsset(entry),
      ),
      this.options,
    );
  }

  contentType(): string {
    return this.get('sys.contentType.sys.id');
  }

  contentId(): string {
    return this.get('sys.id');
  }
}
