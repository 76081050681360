import { AsyncThunkPayloadCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { LANGS } from '../../constants/lang';
import { ProfileEntry } from '../../utils/exclusive/types';
import callDataAPI from '../../utils/universal/data/callDataAPI';
import { RootState } from '../store';

export interface FetchHeroesByPageParams {
  startPageNo: number;
  endPageNo?: number;
  status: string;
  search: string;
}
export interface FetchHeroesByPageResult {
  items: ProfileEntry[];
  status: string;
  search: string;
  page: number;
  nextPage?: number;
}

export const listHeroes: AsyncThunkPayloadCreator<
  FetchHeroesByPageResult,
  FetchHeroesByPageParams,
  { state: RootState }
> = (
  { startPageNo = 1, endPageNo = startPageNo, status, search },
  thunkAPI,
) => {
  const state = thunkAPI.getState();
  const lang = state.settings.lang.value;
  const locale = LANGS[lang].dataCode;
  return callDataAPI(
    `/heroes`,
    {
      page: `${startPageNo}-${endPageNo}`,
      status,
      search,
    },
    { locale },
  );
};

export const fetchHeroById = (
  profileId: string,
): Promise<{ item: ProfileEntry }> => {
  return callDataAPI(`/heroes/${profileId}`);
};
