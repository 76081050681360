import React from 'react';
import { Document as RichTextDocument } from '@contentful/rich-text-types';
import { RichTextSectionFields } from '../../../utils/exclusive/types';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import RichTextSection from '../../universal/RichTextSection';

export interface RichTextSectionCTProps {
  data: CFEntry<RichTextSectionFields>;
}

const RichTextSectionCT = ({ data }: RichTextSectionCTProps) => {
  const document = data.field<RichTextDocument>('contents');
  return <RichTextSection document={document} />;
};

export default RichTextSectionCT;
