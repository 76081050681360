import { parseHeroesQueryOptions } from '../../utils/exclusive/heroes';
import { LoaderProps } from '../types';
import { fetchHeroByIdDispatcher, listHeroesDispatcher } from './dispatchers';

export const fetchHeroByIdLoader = ({ match: { params } }: LoaderProps) =>
  fetchHeroByIdDispatcher(String(params.heroId));

export const listHeroesLoader = ({ location: { query } }: LoaderProps) => {
  const { page: endPageNo, search, status } = parseHeroesQueryOptions(query);
  return listHeroesDispatcher({
    startPageNo: 1,
    endPageNo,
    search,
    status,
  });
};
