import styled from 'styled-components';

const RadioButton = styled.input.attrs({ type: 'radio' })`
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
`;

export default RadioButton;
