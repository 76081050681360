import React, { ReactNode } from 'react';
import useMenuContext from '../Menu/context/useMenuContext';
import staticTextContext, { StaticTextConstants } from './staticTextContext';

const { Provider } = staticTextContext;

export interface StaticTextProviderProps {
  constants: StaticTextConstants;
  children: ReactNode;
}

const StaticTextProvider = ({
  constants,
  children,
}: StaticTextProviderProps) => {
  const { currentLang } = useMenuContext();
  return (
    <Provider value={{ lang: currentLang.value, constants }}>
      {children}
    </Provider>
  );
};

export default StaticTextProvider;
