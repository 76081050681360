import styled from 'styled-components';
import { Theme } from '../../../../../styles/exclusive/theme';
import LangLink from '../../LangSelection/LangLink';
import { RealLinkProps } from '../../types';

export interface MenuSideBarLinkProps extends RealLinkProps {
  theme: Theme;
}

const MenuSideBarLink = styled(LangLink)`
  display: block;
  text-decoration: none;
`;

export default MenuSideBarLink;
