import { useState, useEffect, useCallback, DependencyList } from 'react';

export interface UseEntryTriggerOptions {
  deps?: DependencyList | undefined;
  onExit?: () => unknown;
  timeout?: number;
  activeInSSR?: boolean;
}

const useEntryTrigger = (
  condition: unknown,
  params: UseEntryTriggerOptions = {},
): [boolean, () => void] => {
  const { deps = [], onExit, timeout, activeInSSR = true } = params;
  const defaultActiveValue = activeInSSR ? Boolean(globalThis.global) : false;
  const [active, setActive] = useState(defaultActiveValue);

  useEffect(() => {
    if (condition) {
      setActive(true);
    }
  }, [condition, ...deps]);

  const exit = useCallback(() => {
    if (onExit && timeout) {
      setActive(false);
      setTimeout(onExit, timeout);
    }
  }, [onExit, timeout]);

  return [active, exit];
};

export default useEntryTrigger;
