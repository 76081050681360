import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLocation } from '../../data/router/reducer';

const useReduxLocationSync = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(setLocation(location));
  }, [location]);
};

export default useReduxLocationSync;
