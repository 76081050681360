import React, { HTMLAttributes } from 'react';
import useLangDir from '../../../hooks/exclusive/useLangDir';

export interface DirectedText extends HTMLAttributes<HTMLDivElement> {}

const DirectedText = (props: DirectedText) => {
  const direction = useLangDir();
  return <div {...props} style={{ direction }} />;
};

export default DirectedText;
