import { StaticTextGeneratorParams } from '../components/universal/StaticText/staticTextContext';

const TEXT = {
  TITLE: {
    en: 'Remember Iran',
    fa: 'يادبود ايران',
  },
  DEFAULT_TITLE: {
    en: '%s | Remember Iran',
    fa: '%s | يادبود ايران',
  },
  HEADER: {
    LANG_BUTTON: {
      en: `Language: English`,
      fa: `زبان: فارسی`,
    },
    SIDE_MENU_GO_BACK_BUTTON: {
      en: `Go back`,
      fa: `برگرد`,
    },
  },
  FOOTER: {
    CENTER_TEXT: {
      en: `Woman. Life. Freedom.`,
      fa: `زن. زندگی. آزادی.`,
    },
  },
  PROFILES: {
    TITLE: ({ name }: StaticTextGeneratorParams) => ({
      en: `${name} | Heroes`,
      fa: `${name} | قهرمانان`,
    }),
    INDEX: {
      FILTERS: {
        ALL: {
          en: 'All',
          fa: 'همه',
        },
        DEAD: {
          en: 'Dead',
          fa: 'شهید',
        },
        IN_PRISON: {
          en: 'In prison',
          fa: 'در زندان',
        },
        ON_DEATH_ROW: {
          en: 'On death row',
          fa: 'در محکومیت اعدام',
        },
        UNKNOWN: {
          en: 'Unknown',
          fa: 'نا معلوم',
        },
      },
      SEARCH_INPUT: {
        ARIA_LABEL: {
          en: 'Search heroes by name',
          fa: 'جستجوی قهرمانان با نام',
        },
        PLACEHOLDER: {
          en: 'Search heroes by name...',
          fa: 'جستجوی قهرمانان با نام ...',
        },
      },
      PREV_PAGE_BUTTON: {
        en: 'Previous page',
        fa: 'صفحه قبلی',
      },
      LOAD_MORE_BUTTON: {
        en: 'Load more...',
        fa: 'صفحه بعد...',
      },
    },
    DETAIL: {
      MAIN: {
        HEADERS: {
          SEX: {
            en: 'Sex',
            fa: 'جنسیت',
          },
          STATUS: {
            en: 'Status',
            fa: 'وضعیت',
          },
          DATE_OF_BIRTH: {
            en: 'Date of birth',
            fa: 'تاریخ تولد',
          },
          DATE_OF_ARREST: {
            en: 'Date of arrest',
            fa: 'تاریخ دستگیری',
          },
          DATE_OF_DEATH: {
            en: 'Date of death',
            fa: 'تاریخ مرگ',
          },
          PLACE_OF_BIRTH: {
            en: 'Place of birth',
            fa: 'محل تولد',
          },
          PLACE_OF_ARREST: {
            en: 'Place of arrest',
            fa: 'محل بازداشت',
          },
          PLACE_OF_DEATH: {
            en: 'Place of death',
            fa: 'محل مرگ',
          },
          AGE: {
            en: 'Age',
            fa: 'سن',
          },
          OCCUPATION: {
            en: 'Occupation',
            fa: 'اشتغال',
          },
        },
        VALUES: {
          STATUS: ({ status }: StaticTextGeneratorParams) => {
            return {
              Dead: {
                en: 'Dead',
                fa: 'شهید',
              },
              'In prison': {
                en: 'In prison',
                fa: 'در زندان',
              },
              'On death row': {
                en: 'On death row',
                fa: 'در محکومیت اعدام',
              },
              Unknown: {
                en: 'Unknown',
                fa: 'نا معلوم',
              },
              Convicted: {
                en: 'Convicted',
                fa: 'محکوم',
              },
              'Released on bail': {
                en: 'Released on bail',
                fa: 'با قرار وثیقه آزاد شد',
              },
              Wounded: {
                en: 'Wounded',
                fa: 'مجروح',
              },
            }[status as string];
          },
          SEX: ({ sex }: StaticTextGeneratorParams) => {
            return {
              Male: { en: 'Male', fa: 'مرد/پسر' },
              Female: { en: 'Female', fa: 'زن/دختر' },
              Unknown: {
                en: '?',
                fa: '؟',
              },
            }[sex as string];
          },
          UNKNOWN: {
            en: '?',
            fa: '؟',
          },
        },
      },
      GALLERY: {
        PREV_BUTTON_ARIA_LABEL: {
          en: 'Previous picture button',
          fa: 'دکمه تصویر قبلی',
        },
        NEXT_BUTTON_ARIA_LABEL: {
          en: 'Next picture button',
          fa: 'دکمه تصویر بعدی',
        },
      },
      SEE_ALL_HEROES_BUTTON: {
        en: `See all heroes`,
        fa: `همه قهرمانان را ببینید`,
      },
    },
  },
};

export default TEXT;
