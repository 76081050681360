import React from 'react';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import {
  ProfileFields,
  ProfileSingleLocaleFields,
} from '../../../utils/exclusive/types';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableDataCell,
} from '../../universal/Table';
import useLang from '../../../hooks/exclusive/useLang';
import { convertNumbersToFarsiCharsInText } from '../../../utils/universal/intl';
import StaticText from '../../universal/StaticText/StaticText';
import staticText from '../../../utils/exclusive/staticText';
import {
  calculateAge,
  formatDate,
  riTestIdGen,
} from '../../../utils/exclusive/misc';

export interface MainProfileDetailsProps {
  profile: CFEntry<ProfileFields>;
}

const MainProfileDetails = ({ profile }: MainProfileDetailsProps) => {
  const {
    sex,
    status,
    dateOfBirth,
    dateOfArrest,
    dateOfDeath,
    placeOfBirth,
    placeOfArrest,
    placeOfDeath,
    age,
    occupation,
  } = profile.fields<ProfileSingleLocaleFields>();
  const lang = useLang();
  const unknown = staticText({
    path: 'PROFILES.DETAIL.MAIN.VALUES.UNKNOWN',
    lang,
  });
  const hasPrisonRelatedStatus = [
    'In prison',
    'On death row',
    'Convicted',
    'Released on bail',
  ].includes(status as string);
  const calculatedAge = calculateAge({
    status,
    dateOfBirth,
    dateOfDeath,
    age,
    unknown,
  });
  return (
    <Table
      threshold={720}
      verticalColumnMinWidth={120}
      singleDataRow
      data-testid={riTestIdGen('profile-main-details')}
    >
      <TableHead>
        <TableRow>
          <TableHeaderCell
            data-testid={riTestIdGen('profile-main-details-header-cell-sex')}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.SEX" />
          </TableHeaderCell>
          <TableHeaderCell
            data-testid={riTestIdGen('profile-main-details-header-cell-status')}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.STATUS" />
          </TableHeaderCell>
          <TableHeaderCell
            data-testid={riTestIdGen(
              'profile-main-details-header-cell-date-of-birth',
            )}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.DATE_OF_BIRTH" />
          </TableHeaderCell>
          {hasPrisonRelatedStatus || dateOfArrest ? (
            <TableHeaderCell
              data-testid={riTestIdGen(
                'profile-main-details-header-cell-date-of-arrest',
              )}
            >
              <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.DATE_OF_ARREST" />
            </TableHeaderCell>
          ) : null}
          {status === 'Dead' ? (
            <TableHeaderCell
              data-testid={riTestIdGen(
                'profile-main-details-header-cell-date-of-death',
              )}
            >
              <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.DATE_OF_DEATH" />
            </TableHeaderCell>
          ) : null}
          <TableHeaderCell
            data-testid={riTestIdGen(
              'profile-main-details-header-cell-place-of-birth',
            )}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.PLACE_OF_BIRTH" />
          </TableHeaderCell>
          {hasPrisonRelatedStatus || placeOfArrest ? (
            <TableHeaderCell
              data-testid={riTestIdGen(
                'profile-main-details-header-cell-place-of-arrest',
              )}
            >
              <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.PLACE_OF_ARREST" />
            </TableHeaderCell>
          ) : null}
          {status === 'Dead' ? (
            <TableHeaderCell
              data-testid={riTestIdGen(
                'profile-main-details-header-cell-place-of-death',
              )}
            >
              <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.PLACE_OF_DEATH" />
            </TableHeaderCell>
          ) : null}
          <TableHeaderCell
            data-testid={riTestIdGen('profile-main-details-header-cell-age')}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.AGE" />
          </TableHeaderCell>
          <TableHeaderCell
            data-testid={riTestIdGen(
              'profile-main-details-header-cell-occupation',
            )}
          >
            <StaticText path="PROFILES.DETAIL.MAIN.HEADERS.OCCUPATION" />
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableDataCell
            data-testid={riTestIdGen('profile-main-details-data-cell-sex')}
          >
            {(
              <StaticText
                path="PROFILES.DETAIL.MAIN.VALUES.SEX"
                params={{ sex }}
              />
            ) ?? unknown}
          </TableDataCell>
          <TableDataCell
            data-testid={riTestIdGen('profile-main-details-data-cell-status')}
          >
            {(
              <StaticText
                path="PROFILES.DETAIL.MAIN.VALUES.STATUS"
                params={{ status }}
              />
            ) ?? unknown}
          </TableDataCell>
          <TableDataCell
            data-testid={riTestIdGen(
              'profile-main-details-data-cell-date-of-birth',
            )}
          >
            {formatDate(dateOfBirth, lang, unknown)}
          </TableDataCell>
          {hasPrisonRelatedStatus || dateOfArrest ? (
            <TableDataCell
              data-testid={riTestIdGen(
                'profile-main-details-data-cell-date-of-arrest',
              )}
            >
              {formatDate(dateOfArrest, lang, unknown)}
            </TableDataCell>
          ) : null}
          {status === 'Dead' ? (
            <TableDataCell
              data-testid={riTestIdGen(
                'profile-main-details-data-cell-date-of-death',
              )}
            >
              {formatDate(dateOfDeath, lang, unknown)}
            </TableDataCell>
          ) : null}
          <TableDataCell
            data-testid={riTestIdGen(
              'profile-main-details-data-cell-place-of-birth',
            )}
          >
            {placeOfBirth ?? unknown}
          </TableDataCell>
          {hasPrisonRelatedStatus || placeOfArrest ? (
            <TableDataCell
              data-testid={riTestIdGen(
                'profile-main-details-data-cell-place-of-arrest',
              )}
            >
              {placeOfArrest ?? unknown}
            </TableDataCell>
          ) : null}
          {status === 'Dead' ? (
            <TableDataCell
              data-testid={riTestIdGen(
                'profile-main-details-data-cell-place-of-death',
              )}
            >
              {placeOfDeath ?? unknown}
            </TableDataCell>
          ) : null}
          <TableDataCell
            data-testid={riTestIdGen('profile-main-details-data-cell-age')}
          >
            {lang === 'fa'
              ? convertNumbersToFarsiCharsInText(calculatedAge)
              : calculatedAge}
          </TableDataCell>
          <TableDataCell
            data-testid={riTestIdGen(
              'profile-main-details-data-cell-occupation',
            )}
          >
            {occupation ?? unknown}
          </TableDataCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default MainProfileDetails;
