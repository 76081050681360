import { Langs } from '../components/universal/Menu/types';

export const DEFAULT_LANG_CODE = 'en';

export const LANGS: Langs = {
  en: {
    code: 'en',
    label: {
      en: 'English',
      fa: 'انگلیسی',
    },
    dataCode: 'en-US',
    direction: 'ltr',
  },
  fa: {
    code: 'fa',
    label: {
      en: 'Farsi',
      fa: 'فارسی',
    },
    dataCode: 'fa',
    direction: 'rtl',
  },
};
