import React from 'react';
import Switch, { SwitchProps } from './Switch';
import _Label from '../Label';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme';

export interface LabelledSwitchProps<OnValue = true, OffValue = false>
  extends SwitchProps<OnValue, OffValue> {
  label: string;
  testId: string;
}

const Label = styled(_Label)`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 0;

  ${({ theme }: { theme: Theme }) => {
    return css`
      border: 1px solid ${theme.colors.Brandy};
    `;
  }}
`;

const Text = styled.div`
  padding: 5px 10px;
`;

const LabelledSwitch = <OnValue = true, OffValue = false>({
  label,
  testId,
  ...props
}: LabelledSwitchProps<OnValue, OffValue>) => {
  return (
    <Label data-testid={testId}>
      <Text data-testid="label">{label}</Text>
      <Switch data-testid="switch" {...props} />
    </Label>
  );
};

export default LabelledSwitch;
