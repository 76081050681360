import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MenuEntry } from '../../utils/exclusive/types';
import { fetchMenus } from './apis';

export const fetchMenusAction = createAsyncThunk(
  'menus/fetchMenus',
  fetchMenus,
);

export type MenusStoreState = {
  loaded: boolean;
  items: MenuEntry[];
};

const initialState: MenusStoreState = {
  loaded: false,
  items: [],
};

const { reducer } = createSlice({
  name: 'menus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMenusAction.fulfilled, (state, action) => {
      const { items } = action.payload;
      state.loaded = true;
      state.items = items;
    });
  },
});

export const menusReducer = reducer;
