import React, { ChangeEvent, InputHTMLAttributes, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme';
import Checkbox from '../Checkbox/Checkbox';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StateLabel = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }: { theme: Theme }) => {
    return css`
      border-left: 1px solid ${theme.colors.Brandy};
    `;
  }}
`;

const sharedOnOffLabelStyles = css`
  ${({ theme }: { theme: Theme }) => {
    return css`
      padding: 5px 10px;
      background-color: ${theme.colors.Brandy};
    `;
  }}
`;

const OnLabel = styled.div.attrs({ children: 'On' })`
  ${sharedOnOffLabelStyles}
`;

const OffLabel = styled.div.attrs({ children: 'Off' })`
  ${sharedOnOffLabelStyles}
`;

export interface SwitchProps<OnValue = true, OffValue = false>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value' | 'defaultValue'
  > {
  onChange?: (params: {
    event: ChangeEvent<HTMLInputElement>;
    value: OnValue | OffValue;
    checked: boolean;
  }) => void;
  onValue?: OnValue | OffValue;
  offValue?: OnValue | OffValue;
  value?: OnValue | OffValue;
}

const Switch = <OnValue = true, OffValue = false>(
  props: SwitchProps<OnValue, OffValue>,
) => {
  const onValue = (props.onValue ?? true) as OnValue;
  const offValue = (props.offValue ?? false) as OffValue;

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      props.onChange?.({
        event,
        value: event.currentTarget.checked ? onValue : offValue,
        checked: event.currentTarget.checked,
      });
    },
    [props.onChange],
  );

  const checked = (onValue === props.value) === onValue ? true : false;

  return (
    <Wrapper>
      <StateLabel>
        <OffLabel
          style={{ visibility: !checked ? 'visible' : 'hidden' }}
          data-testid="off"
        />
        <OnLabel
          style={{ visibility: checked ? 'visible' : 'hidden' }}
          data-testid="on"
        />
      </StateLabel>
      <Checkbox onChange={onChange} checked={checked} />
    </Wrapper>
  );
};

export default Switch;
