import { createSlice, isRejected, SerializedError } from '@reduxjs/toolkit';

export type RejectedActionMeta = {
  arg: unknown;
  requestId: string;
  requestStatus: 'rejected';
  aborted: boolean;
  condition: boolean;
} & (
  | {
      rejectedWithValue: true;
    }
  | ({
      rejectedWithValue: false;
    } & {})
);

export interface ErrorStoreState {
  error?: SerializedError;
  meta?: RejectedActionMeta;
}

const initialState = {
  error: undefined,
  meta: undefined,
} as ErrorStoreState;

const { reducer } = createSlice({
  name: 'error',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isRejected, (state, action) => {
      state.error = action.error;
      state.meta = action.meta;
    });
  },
});

export const errorReducer = reducer;
