import React from 'react';
import { SectionLinkFields } from '../../../utils/exclusive/types';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import SectionLink from '../../universal/SectionLink';

export interface SectionLinkCTProps {
  data: CFEntry<SectionLinkFields>;
}

const SectionLinkCT = ({ data }: SectionLinkCTProps) => {
  return (
    <SectionLink
      link={data.field<string>('link')}
      text={data.field<string>('text')}
      image={data.asset('image').file().url}
    />
  );
};

export default SectionLinkCT;
