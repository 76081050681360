import { createGlobalStyle, css } from 'styled-components';
import { LangDirection } from '../../components/universal/Menu/types';
import { Theme } from './theme';

const GlobalStyles = createGlobalStyle`
  ${({ theme, direction }: { theme: Theme; direction: LangDirection }) => css`
    body {
      margin: 0;
      background-color: ${theme.colors.RaisinBlack};
      color: ${theme.colors.Cultured};
      transition: opacity 1s ease-in-out;

      &.disable-scrolling {
        overflow: hidden;
      }
    }

    * {
      font-family: 'Vazirmatn', 'Raleway', sans-serif;
      box-sizing: border-box;
    }

    th {
      font-weight: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }

    html {
      font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
    }

    #app-wrapper {
      min-height: 100vh;
      position: relative;
    }
  `}
`;

export default GlobalStyles;
