import { PropsWithChildren, ReactElement } from 'react';
import {
  HelmetProvider as _HelmetProvider,
  Helmet as _Helmet,
  HelmetProps,
  ProviderProps,
} from 'react-helmet-async';

export const HelmetProvider = _HelmetProvider as unknown as (
  props: PropsWithChildren<ProviderProps>,
) => ReactElement;

export const Helmet = _Helmet as unknown as (
  props: PropsWithChildren<HelmetProps>,
) => ReactElement;
