import { useSelector } from 'react-redux';
import { LANGS } from '../../constants/lang';
import { RootState } from '../../data/store';

const useLangDir = () => {
  const lang = useSelector<RootState, string>(
    (state) => state.settings.lang.value,
  );
  return LANGS[lang].direction;
};

export default useLangDir;
