export type QSInput =
  | string
  | string[][]
  | Record<string, any>
  | URLSearchParams;

export const parseQS = (init: QSInput, removeEmptyValues = true) => {
  if (
    removeEmptyValues &&
    init &&
    typeof init === 'object' &&
    !(init instanceof URLSearchParams) &&
    !Array.isArray(init)
  ) {
    init = Object.fromEntries(
      Object.entries(init)
        .filter(([, value]) => ![undefined, null, ''].includes(value))
        .map(([key, value]) => [key, String(value)]),
    );
  }
  return new URLSearchParams(init);
};

export const stringifyQS = (
  init: QSInput,
  includeLeadingQuestionMark = true,
) => {
  const instance = parseQS(init);
  instance.sort();
  let str = instance.toString();
  if (includeLeadingQuestionMark) {
    str = `?${str}`;
  }
  return str;
};

export const parseLocationQS = ({ search }: { search: string }) =>
  parseQS(search);

export const parseURLFromLocation = (
  { pathname, search }: { pathname: string; search: string },
  base?: string,
) => parseURLFromPath(`${pathname}${search}`, base);

export const parseURLFromPath = (
  path: string,
  base: string = globalThis.location?.href ?? 'http://localhost',
) => new URL(path, base);

export const areQueryStringsTheSame = (
  initA: QSInput,
  initB: QSInput,
  ignoreQueryStrings: string[] = [],
) => {
  const instanceA = parseQS(initA);
  const instanceB = parseQS(initB);

  for (const qsToIgnore of ignoreQueryStrings) {
    instanceA.delete(qsToIgnore);
    instanceB.delete(qsToIgnore);
  }

  return stringifyQS(instanceA, false) === stringifyQS(instanceB, false);
};

export const areURLPathAndQSTheSame = (
  urlA: URL,
  urlB: URL,
  ignoreQueryStrings?: string[],
) => {
  return (
    urlA.pathname === urlB.pathname &&
    areQueryStringsTheSame(
      urlA.searchParams,
      urlB.searchParams,
      ignoreQueryStrings,
    )
  );
};
