import React from 'react';
import styled from 'styled-components';
import ImageGrid from '../../universal/ImageGrid';
import ImageGridItem from '../../universal/ImageGrid/ImageGridItem';
import useProfileListData from '../../../hooks/exclusive/useProfileListData';
import { Helmet } from '../../universal/Helmet';
import { LinkButton } from '../../universal/Form/Button';
import SectionGenerator from '../../universal/SectionGenerator';
import { stringifyHeroesQueryOptions } from '../../../utils/exclusive/heroes';
import ProfileGridFilter from './ProfileGridFilter';
import Loader from '../../universal/Loader';
import DirectedText from '../utils/DirectedText';
import useLangDir from '../../../hooks/exclusive/useLangDir';
import useLang from '../../../hooks/exclusive/useLang';
import StaticText from '../../universal/StaticText/StaticText';
import { riTestIdGen } from '../../../utils/exclusive/misc';

const GridItemPersianName = styled.div.attrs({
  lang: 'fa',
})`
  font-size: 50%;
  direction: rtl;
`;

const PreviousPageButton = styled(LinkButton)`
  margin-bottom: 15px;
`;

const ProfileGrid = () => {
  const { page, profiles, nextPage, status, search } = useProfileListData();
  const direction = useLangDir();
  const lang = useLang();

  const gridElementsList = profiles.map((profile) => {
    const id = profile.field<string>('id');
    return (
      <ImageGridItem
        key={id}
        title={
          <div>
            {profile.field<string>('name')}
            {lang !== 'fa' && (
              <GridItemPersianName>
                {profile.fa().field<string>('name')}
              </GridItemPersianName>
            )}
          </div>
        }
        image={profile.assets('pictures').data?.[0]?.file()?.url ?? ''}
        url={`/heroes/${id}`}
        testId={riTestIdGen(`grid-item-${id}`)}
      />
    );
  });

  let prePageQuery: string | undefined;
  let nexPageQuery: string | undefined;

  if (page > 1) {
    prePageQuery = stringifyHeroesQueryOptions({
      page: page - 1,
      status,
      search,
    });
  }

  if (nextPage) {
    nexPageQuery = stringifyHeroesQueryOptions({
      page: nextPage,
      status,
      search,
    });

    if (profiles.length) {
      gridElementsList.push(
        <ImageGridItem
          key="load-more-grid-item"
          title={
            <div>
              <StaticText path="PROFILES.INDEX.LOAD_MORE_BUTTON" />
            </div>
          }
          url={`/heroes${nexPageQuery}`}
          testId={riTestIdGen('grid-item-loader-more')}
        />,
      );
    } else {
      gridElementsList.push(
        <ImageGridItem key="loader-grid-item" title={<Loader />} />,
      );
    }
  }

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://rememberiran.com/heroes${stringifyHeroesQueryOptions({
            page,
            status,
            search,
          })}`}
        />
        {prePageQuery ? (
          <link
            rel="prev"
            href={`https://rememberiran.com/heroes${prePageQuery}`}
          />
        ) : null}
        {nexPageQuery ? (
          <link
            rel="next"
            href={`https://rememberiran.com/heroes${nexPageQuery}`}
          />
        ) : null}
      </Helmet>
      <ProfileGridFilter direction={direction} />
      <noscript>
        {prePageQuery ? (
          <DirectedText>
            <PreviousPageButton to={`/heroes${prePageQuery}`}>
              <StaticText path="PROFILES.INDEX.PREV_PAGE_BUTTON" />
            </PreviousPageButton>
          </DirectedText>
        ) : null}
      </noscript>
      <DirectedText>
        <SectionGenerator>
          <ImageGrid
            gap={15}
            minColumnWidth={250}
            testId={riTestIdGen('profile-image-grid')}
          >
            {gridElementsList}
          </ImageGrid>
        </SectionGenerator>
      </DirectedText>
    </>
  );
};

export default ProfileGrid;
