import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import useMenuContext from '../context/useMenuContext';
import { prefixLang, prepLangUrl } from '../lang';

const useLangNavigate = () => {
  const navigate = useNavigate();
  const { currentLang, langs } = useMenuContext();
  return (to: To, options?: NavigateOptions) => {
    const url =
      typeof to === 'string'
        ? to
        : prepLangUrl({
            pathname: to.pathname ?? '',
            search: to.search ?? '',
            hash: to.hash ?? '',
          });

    const prefixedUrl = prefixLang({
      url,
      langs,
      langToPrefix: currentLang.value,
    });
    return navigate(prefixedUrl, options);
  };
};

export default useLangNavigate;
