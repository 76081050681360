import React from 'react';
import styled from 'styled-components';
import { Document } from '@contentful/rich-text-types';
import Gallery from '../../universal/Gallery';
import MainProfileDetails from './MainProfileDetails';
import RichText from '../../universal/RichText';
import { Helmet } from '../../universal/Helmet';
import { LinkButton } from '../../universal/Form/Button';
import Heading from '../../universal/Heading';
import useProfileData from '../../../hooks/exclusive/useProfileData';
import Section from '../../universal/Section';
import FixedWidthWrapper from '../../universal/FixedWidthWrapper';
import useLang from '../../../hooks/exclusive/useLang';
import DirectedText from '../utils/DirectedText';
import { useMenuLangDir } from '../../universal/Menu/lang';
import staticText from '../../../utils/exclusive/staticText';
import StaticText from '../../universal/StaticText/StaticText';
import { riTestIdGen } from '../../../utils/exclusive/misc';

const Wrapper = styled(DirectedText)`
  display: grid;
  grid-gap: 15px;
  grid-row: 1fr;
`;

const ProfileHeading = styled(Heading)`
  padding-bottom: 10px;
`;

const PersianName = styled.div.attrs({
  lang: 'fa',
})`
  font-size: 50%;
  padding-top: 10px;
  direction: rtl;
`;

const AllHeroesButton = styled(LinkButton)``;

// TODO: autogenerate description from information. Use description both for meta description tags and as a default stand-in for bio

const Profile = () => {
  const data = useProfileData();
  const lang = useLang();
  const direction = useMenuLangDir();

  if (!data) {
    return null;
  }

  const pictures = data.assets('pictures');
  const id = data.field<string>('id');
  const metaImage = pictures.data?.[0]?.file()?.url;
  return (
    <Section>
      <FixedWidthWrapper>
        <Wrapper data-testid={riTestIdGen('profile')}>
          <Helmet>
            <title>
              {staticText({
                lang,
                path: 'PROFILES.TITLE',
                params: {
                  name: data.field<string>('name'),
                },
              })}
            </title>
            {/* <meta name="description" content={description} /> */}
            <meta property="og:title" content={data.field<string>('name')} />
            {/* <meta property="og:description" content={description} /> */}
            {metaImage ? (
              <meta property="og:image" content={metaImage} />
            ) : null}
            <link
              rel="canonical"
              href={`https://rememberiran.com/heroes/${id}`}
            />
          </Helmet>
          <ProfileHeading
            level={1}
            size={1}
            data-testid={riTestIdGen('profile-heading')}
          >
            {data?.field<string>('name') ?? ''}
            {lang !== 'fa' && (
              <PersianName>
                {data?.fa().field<string>('name') ?? ''}
              </PersianName>
            )}
          </ProfileHeading>
          {pictures.data.length ? (
            <Gallery
              overrideAlts={new Array(pictures.data.length).fill(
                data?.field<string>('name') ?? '',
              )}
              images={pictures}
              direction={direction}
              testId={riTestIdGen('profile-gallery')}
            />
          ) : null}
          <MainProfileDetails profile={data} />
          <div data-testid={riTestIdGen('profile-bio')}>
            <RichText document={data.field<Document>('bio')} />
          </div>
          <AllHeroesButton
            to="/heroes"
            date-testid={riTestIdGen('profile-all-heroes-button')}
          >
            <StaticText path="PROFILES.DETAIL.SEE_ALL_HEROES_BUTTON" />
          </AllHeroesButton>
        </Wrapper>
      </FixedWidthWrapper>
    </Section>
  );
};

export default Profile;
