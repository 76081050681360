import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'usehooks-ts';
import { DoublyLinkedList } from '@datastructures-js/linked-list';
import { Theme } from '../../styles/exclusive/theme';
import ArrowButton from './icon-buttons/ArrowButton';
import CFList from '../../utils/universal/contentful/CFList';
import CFAsset from '../../utils/universal/contentful/CFAsset';
import { LangDirection } from './Menu/types';
import useMenuContext from './Menu/context/useMenuContext';
import staticText from '../../utils/exclusive/staticText';

export interface WrapperProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  centerContents: boolean;
}

const Wrapper = styled(({ centerContents, ...props }: WrapperProps) => (
  <div {...props} />
))`
  ${({
    theme,
    centerContents,
  }: {
    theme: Theme;
    centerContents: boolean;
  }) => css`
    display: flex;
    padding: 0 10px;
    overflow: hidden;
    border: 3px solid ${theme.colors.SpanishGray};
    box-shadow: 0 0 10px 1px ${theme.colors.SpanishGray};
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;

    ${centerContents
      ? css`
          align-items: center;
          flex-direction: column;
        `
      : ''}

    @media only screen and (max-width: 999px) {
      align-items: center;
      flex-direction: column;
      padding: 0;
    }
  `}
`;

const GalleryImageWrapper = styled.div`
  position: relative;
  width: calc(100% - 140px);
  height: 100%;
  padding: 0 10px;
  height: 500px;

  @media only screen and (max-width: 999px) {
    max-width: 100%;
    width: 100%;
    padding: 0;
    height: 400px;
  }

  @media only screen and (max-width: 500px) {
    height: 300px;
  }
`;

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-top: 3px solid ${({ theme }: { theme: Theme }) => theme.colors.Brandy};
`;

export interface GalleryImageProps {
  active: boolean;
}

const GalleryImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  opacity: ${({ active }: GalleryImageProps) => (active ? 1 : 0)};
  transition: opacity 300ms ease-in-out;
  max-width: 100%;
  max-height: 100%;
`;

const LeftButton = styled(ArrowButton).attrs({
  direction: 'left',
  size: 70,
})`
  @media only screen and (max-width: 999px) {
    width: 50px;
  }
`;

const RightButton = styled(ArrowButton).attrs({
  direction: 'right',
  size: 70,
})`
  @media only screen and (max-width: 999px) {
    width: 50px;
  }
`;

export interface GalleryProps {
  images: CFList<CFAsset>;
  direction: LangDirection;
  overrideAlts?: string[];
  testId?: string;
}

type GalleryNavButton = (props: {
  onClick: (params: any) => void;
}) => ReactElement;

const Gallery = ({
  images: imageAssets,
  direction,
  overrideAlts,
  testId,
}: GalleryProps) => {
  const images = useMemo(() => {
    const imgList = DoublyLinkedList.fromArray<CFAsset>(imageAssets.data);
    imgList.tail().setNext(imgList.head());
    imgList.head().setPrev(imgList.tail());
    return imgList;
  }, [imageAssets]);
  const [currentImage, setCurrentImage] = useState(images.head());
  const onLeftClick = useCallback(() => {
    setCurrentImage(currentImage.getPrev());
  }, [currentImage]);
  const onRightClick = useCallback(() => {
    setCurrentImage(currentImage.getNext());
  }, [currentImage]);
  const isSmallScreen = useMediaQuery('(max-width: 999px)');
  const {
    currentLang: { value: lang },
  } = useMenuContext();

  const PrevButton = (
    direction === 'ltr' ? LeftButton : RightButton
  ) as GalleryNavButton;
  const NextButton = (
    direction === 'ltr' ? RightButton : LeftButton
  ) as GalleryNavButton;

  return (
    <Wrapper centerContents={images.count() === 1} data-testid={testId}>
      {isSmallScreen && images.count() > 1 ? (
        <>
          <GalleryImageWrapper data-testid="gallery-image-wrapper">
            {imageAssets.data.map((img, index) => (
              <GalleryImage
                key={img.assetId()}
                src={img.file().url}
                alt={overrideAlts?.[index] ?? img.alt()}
                active={img.assetId() === currentImage.getValue().assetId()}
                data-testid="gallery-image"
              />
            ))}
          </GalleryImageWrapper>
          <ArrowsWrapper>
            <PrevButton
              data-testid="gallery-navigation-prev-button"
              onClick={onLeftClick}
              aria-label={staticText({
                path: 'PROFILES.DETAIL.GALLERY.PREV_BUTTON_ARIA_LABEL',
                lang,
              })}
            />
            <NextButton
              data-testid="gallery-navigation-next-button"
              onClick={onRightClick}
              aria-label={staticText({
                path: 'PROFILES.DETAIL.GALLERY.NEXT_BUTTON_ARIA_LABEL',
                lang,
              })}
            />
          </ArrowsWrapper>
        </>
      ) : (
        <>
          {images.count() > 1 ? (
            <PrevButton
              data-testid="gallery-navigation-prev-button"
              onClick={onLeftClick}
              aria-label={staticText({
                path: 'PROFILES.DETAIL.GALLERY.PREV_BUTTON_ARIA_LABEL',
                lang,
              })}
            />
          ) : null}
          <GalleryImageWrapper data-testid="gallery-image-wrapper">
            {imageAssets.data.map((img, index) => (
              <GalleryImage
                key={img.assetId()}
                src={img.file().url}
                alt={overrideAlts?.[index] ?? img.alt()}
                active={img.assetId() === currentImage.getValue().assetId()}
                data-testid="gallery-image"
              />
            ))}
          </GalleryImageWrapper>
          {images.count() > 1 ? (
            <NextButton
              data-testid="gallery-navigation-next-button"
              onClick={onRightClick}
              aria-label={staticText({
                path: 'PROFILES.DETAIL.GALLERY.NEXT_BUTTON_ARIA_LABEL',
                lang,
              })}
            />
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default Gallery;
