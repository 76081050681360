import React, { useState, useEffect, ReactNode } from 'react';
import useMenuContext from '../../context/useMenuContext';
import {
  MenuItem,
  ParentMenuItem,
  SideBarMenuParentNode,
  SideMenuItems,
} from '../../types';
import { FlattenedSideMenuItems } from './MenuSideBarHeader';
import MenuSideBarLink from './MenuSideBarLink';
import MenuSideBarList from './MenuSideBarList';
import MenuSideBarListItem from './MenuSideBarListItem';
import MenuSideBarSubMenuToggleButton from './MenuSideBarSubMenuExpandButton';

const isMenuItem = (item: MenuItem | ParentMenuItem): item is MenuItem =>
  Boolean((item as MenuItem).to);

const flattenNavTree = (
  root: SideBarMenuParentNode,
  items: SideMenuItems,
): FlattenedSideMenuItems => {
  const flatList = new Map<SideBarMenuParentNode, SideMenuItems>();
  flatList.set(root, items);

  const walkNavTree = (items: SideMenuItems) => {
    for (const item of items) {
      if (!isMenuItem(item)) {
        flatList.set(item, item.items);
        walkNavTree(item.items);
      }
    }

    return flatList;
  };

  return walkNavTree(items);
};

export interface MenuSideBarItemsProps {
  root: SideBarMenuParentNode;
  onClose: () => void;
  resetMenu: () => void;
  openSubMenu: (item: ParentMenuItem) => () => void;
  isItemListVisible: (parent: SideBarMenuParentNode) => boolean;
}

const MenuSideBarItems = ({
  onClose,
  root,
  resetMenu,
  openSubMenu,
  isItemListVisible,
}: MenuSideBarItemsProps) => {
  const { sideMenuItems, isMenuItemActive, testIdGenerator } = useMenuContext();
  const [flatList, setFlatList] = useState(flattenNavTree(root, sideMenuItems));

  useEffect(() => {
    setFlatList(flattenNavTree(root, sideMenuItems));
    resetMenu();
  }, [sideMenuItems]);

  return (
    <>
      {Array.from(flatList.entries()).map(
        ([parent, items]: [SideBarMenuParentNode, SideMenuItems]) => {
          const itemNodes: ReactNode[] = items.map((item) => {
            let child: ReactNode;
            let active = false;

            if (isMenuItem(item)) {
              active = isMenuItemActive(item.to);
              child = (
                <MenuSideBarLink to={item.to} onClick={onClose}>
                  {item.label}
                </MenuSideBarLink>
              );
            } else {
              child = (
                <MenuSideBarSubMenuToggleButton
                  textPosition="after"
                  onClick={openSubMenu(item)}
                >
                  {item.label}
                </MenuSideBarSubMenuToggleButton>
              );
            }

            return (
              <MenuSideBarListItem key={item.id} active={active}>
                {child}
              </MenuSideBarListItem>
            );
          });

          return (
            <MenuSideBarList
              key={parent.id}
              visible={isItemListVisible(parent)}
              data-testid={testIdGenerator('side-menu-list')}
            >
              {itemNodes}
            </MenuSideBarList>
          );
        },
      )}
    </>
  );
};

export default MenuSideBarItems;
