import Cookies from 'js-cookie';

const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

const cookies = Cookies.withConverter({
  read: (value: string) => {
    return JSON.parse(value);
  },
  write: (value: any) => {
    return JSON.stringify(value);
  },
}).withAttributes({
  secure: true,
  sameSite: 'Lax',
  expires: aYearFromNow,
});

export default cookies;
