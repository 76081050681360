import { createContext } from 'react';
import { DEFAULT_LANG_CODE } from '../../../../constants/lang';
import { MenuContext } from '../types';

export const DEFAULT_PROVIDER_PROPS = {
  topMenuItems: [],
  sideMenuItems: [],
  alwaysShowSideMenuExpandButton: true,
  minimumTopMenuVisibleItems: 0,
  langs: {},
  defaultLang: DEFAULT_LANG_CODE,
  currentLang: {
    value: DEFAULT_LANG_CODE,
    remember: false,
  },
  setCurrentLang: () => {},
  testIdGenerator: (testId: string) => testId,
};

export const DEFAULT_MENU_CONTEXT: MenuContext = {
  isMenuSidebarOpen: false,
  menuSidebarWidth: 0,
  mainContentFixedElementWidthOffset: 0,
  setMenuSidebarWidth: () => {},
  toggleMenuSidebarOpen: () => {},
  isMenuItemActive: () => false,
  isLargeScreen: false,
  isLangMenuActive: false,
  toggleLangMenu: () => {},
  disableLangMenu: () => {},
  enableLangMenu: () => {},
  enableLangSelection: true,
  menuExpandButtonLabel: '',
  sideMenuGoBackButtonLabel: '',
  ...DEFAULT_PROVIDER_PROPS,
};

export const menuContext = createContext(DEFAULT_MENU_CONTEXT);
