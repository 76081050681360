import React, {
  KeyboardEventHandler,
  MutableRefObject,
  useCallback,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import useLang from '../../../hooks/exclusive/useLang';
import useProfileGridFilter from '../../../hooks/exclusive/useProfileGridFilter';
import { Theme } from '../../../styles/exclusive/theme';
import { riTestIdGen } from '../../../utils/exclusive/misc';
import staticText from '../../../utils/exclusive/staticText';
import RadioButtonGroup from '../../universal/Form/Radio/RadioButtonGroup';
import TextInput from '../../universal/Form/TextInput';
import { LangDirection } from '../../universal/Menu/types';
import DirectedText from '../utils/DirectedText';

const Wrapper = styled(DirectedText)`
  position: sticky;
  display: grid;
  top: 65px;
  z-index: 3;
  gap: 15px;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 750px) {
    grid-template-columns: 1fr 400px;
  }

  ${({ theme }: { theme: Theme }) => css`
    background-color: ${theme.colors.RaisinBlack};
    padding: 15px 0;
    margin: -15px 0;
  `}
`;

export interface ProfileGridFilterProps {
  direction: LangDirection;
}

const ProfileGridFilter = ({ direction }: ProfileGridFilterProps) => {
  const { onChange, search, status } = useProfileGridFilter();
  const lang = useLang();
  const textInputRef = useRef<HTMLInputElement>();

  // Close mobile keyboard on submit
  const onKeyUp = useCallback((keyboardEvent) => {
    if (keyboardEvent.code === 'Enter') {
      textInputRef.current?.blur();
    }
  }, []) as KeyboardEventHandler<HTMLInputElement>;

  return (
    <Wrapper data-testid={riTestIdGen('profile-grid-filter')}>
      <TextInput
        name="profile-grid-search"
        placeholder={staticText({
          path: 'PROFILES.INDEX.SEARCH_INPUT.PLACEHOLDER',
          lang,
        })}
        aria-label={staticText({
          path: 'PROFILES.INDEX.SEARCH_INPUT.ARIA_LABEL',
          lang,
        })}
        data-testid={riTestIdGen('profile-search-input')}
        onChange={onChange}
        onKeyUp={onKeyUp}
        value={search}
        spellCheck={false}
        ref={textInputRef as MutableRefObject<HTMLInputElement>}
      />
      <RadioButtonGroup
        testId={riTestIdGen('profile-status-filter')}
        name="profile-grid-status-filter"
        items={[
          {
            label: staticText({ path: 'PROFILES.INDEX.FILTERS.ALL', lang }),
            value: '',
          },
          {
            label: staticText({ path: 'PROFILES.INDEX.FILTERS.DEAD', lang }),
            value: 'Dead',
          },
          {
            label: staticText({
              path: 'PROFILES.INDEX.FILTERS.IN_PRISON',
              lang,
            }),
            value: 'In prison',
          },
          {
            label: staticText({
              path: 'PROFILES.INDEX.FILTERS.ON_DEATH_ROW',
              lang,
            }),
            value: 'On death row',
          },
          {
            label: staticText({ path: 'PROFILES.INDEX.FILTERS.UNKNOWN', lang }),
            value: 'Unknown',
          },
        ]}
        onChange={onChange}
        value={status}
        direction={direction}
      />
    </Wrapper>
  );
};

export default ProfileGridFilter;
