import React, {
  useRef,
  useState,
  forwardRef,
  createContext,
  useContext,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
} from 'react';
import styled, { css } from 'styled-components';
import useEntryTrigger from '../../hooks/universal/useEntryTrigger';
import { Theme } from '../../styles/exclusive/theme';

type TableMode = 'horizontal' | 'vertical';

interface TableContext {
  singleDataRow: boolean;
  mode: TableMode;
}

const defaultContextValue: TableContext = {
  singleDataRow: false,
  mode: 'horizontal',
};

const context = createContext<TableContext>(defaultContextValue);

const { Provider: TableContextProvider } = context;

const Wrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

interface StyledTableProps {
  mode: TableMode;
  singleDataRow: boolean;
  active: boolean;
}

const StyledTable = styled(({ mode, singleDataRow, active, ...props }) => (
  <table {...props} />
))`
  font-size: 1.5rem;
  border-collapse: collapse;
  width: 100%;
  transition: opacity 300ms ease-in-out;

  ${({ mode, singleDataRow, active }: StyledTableProps) => {
    const opacity = active ? 1 : 0;
    if (mode === 'vertical') {
      if (singleDataRow) {
        return css`
          display: flex;
          text-align: center;
          opacity: ${opacity};
        `;
      }
      return css`
        display: flex;
        text-align: left;
        opacity: ${opacity};
      `;
    }
    return css`
      text-align: center;
      opacity: ${opacity};
    `;
  }}
`;

const StyledTableBody = styled(({ mode, singleDataRow, ...props }) => (
  <tbody {...props} />
))`
  ${({ mode, singleDataRow }: { mode: TableMode; singleDataRow: boolean }) => {
    if (mode === 'vertical') {
      if (singleDataRow) {
        return css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        `;
      }
      return css`
        display: block;
      `;
    }
    return '';
  }}
`;

const StyledTableRow = styled(({ mode, width, ...props }) => <tr {...props} />)`
  ${({ mode, width }: { mode: TableMode; width: number }) => css`
    display: ${mode === 'horizontal' ? 'table-row' : 'block'};
    ${width
      ? css`
          width: ${width}px;
        `
      : ''}
  `}
`;

const StyledTableHead = styled(({ mode, ...props }) => <thead {...props} />)`
  ${({ mode }: { mode: TableMode }) => {
    if (mode === 'vertical') {
      return css`
        display: block;
        ${StyledTableRow} {
          border-right: 1px solid
            ${({ theme }: { theme: Theme }) => theme.colors.Brandy};
        }
      `;
    }
    return css`
      ${StyledTableRow} {
        border-bottom: 1px solid
          ${({ theme }: { theme: Theme }) => theme.colors.Brandy};
      }
    `;
  }}
`;

const sharedTableCellStyles = css`
  padding: 10px;
  ${({ mode, theme }: { mode: TableMode; theme: Theme }) => {
    if (mode === 'vertical') {
      return css`
        display: block;
        &:not(:last-child) {
          border-bottom: 1px solid ${theme.colors.Brandy};
        }
      `;
    }
    return css``;
  }}
`;

const StyledTableHeaderCell = styled(({ mode, ...props }) => <th {...props} />)`
  font-weight: 600;
  ${sharedTableCellStyles}
`;

const StyledTableDataCell = styled(({ mode, ...props }) => <td {...props} />)`
  ${sharedTableCellStyles}
`;

export type TableProps = JSX.IntrinsicElements['table'] & {
  threshold: number;
  singleDataRow?: boolean;
  verticalColumnMinWidth?: number;
};

const calculateItemWidth = (
  verticalColumnMinWidth: number,
  containerWidth: number,
): number => {
  if (!containerWidth) {
    return 0;
  }
  const countPerLine = Math.floor(containerWidth / verticalColumnMinWidth);
  return containerWidth / countPerLine;
};

const rearrangeChildren = (
  children: ReactNode,
  verticalColumnMinWidth: number,
  containerWidth: number,
): ReactElement => {
  const [thead, tbody] = children as ReactElement[];
  const headerCells = (
    thead.props.children.props.children as ReactElement[]
  ).filter((element) => Boolean(element));
  const dataRow = tbody.props.children as ReactElement;
  const dataCells = (dataRow.props.children as ReactElement[]).filter(
    (element) => Boolean(element),
  );

  return cloneElement(tbody, {
    children: headerCells.map((headerCell, index) => {
      return cloneElement(dataRow, {
        key:
          typeof headerCell.props.children === 'string'
            ? headerCell.props.children
            : index,
        children: [headerCell, dataCells[index]],
        width: calculateItemWidth(verticalColumnMinWidth, containerWidth),
      });
    }),
  });
};

export const Table = forwardRef(
  (
    {
      threshold,
      children,
      singleDataRow = false,
      verticalColumnMinWidth = 100,
      ...props
    }: TableProps,
    forwardedRef: React.ForwardedRef<HTMLTableElement>,
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const [mode, setMode] = useState<TableMode>('horizontal');
    const [containerWidth, setContainerWidth] = useState(0);
    const [active] = useEntryTrigger(containerWidth);
    useEffect(() => {
      if (ref.current) {
        setContainerWidth(ref.current.clientWidth);
        if (threshold > ref.current.clientWidth) {
          setMode('vertical');
        } else {
          setMode('horizontal');
        }
      }
    }, [globalThis.document?.body.clientWidth, threshold]);
    return (
      <Wrapper ref={ref}>
        <TableContextProvider value={{ mode, singleDataRow }}>
          <StyledTable
            {...props}
            ref={forwardedRef}
            mode={mode}
            singleDataRow={singleDataRow}
            active={active}
          >
            {mode === 'horizontal' || !singleDataRow
              ? children
              : rearrangeChildren(
                  children,
                  verticalColumnMinWidth,
                  containerWidth,
                )}
          </StyledTable>
        </TableContextProvider>
      </Wrapper>
    );
  },
);

export type TableHeadProps = JSX.IntrinsicElements['thead'];

export const TableHead = forwardRef(
  (
    props: TableHeadProps,
    forwardedRef: React.ForwardedRef<HTMLTableSectionElement>,
  ) => {
    const { mode } = useContext(context);
    return <StyledTableHead {...props} ref={forwardedRef} mode={mode} />;
  },
);

export type TableRowProps = JSX.IntrinsicElements['tr'];

export const TableRow = forwardRef(
  (
    props: TableRowProps,
    forwardedRef: React.ForwardedRef<HTMLTableRowElement>,
  ) => {
    const { mode } = useContext(context);
    return <StyledTableRow {...props} ref={forwardedRef} mode={mode} />;
  },
);

export type TableHeaderCellProps = JSX.IntrinsicElements['th'];

export const TableHeaderCell = forwardRef(
  (
    props: TableHeaderCellProps,
    forwardedRef: React.ForwardedRef<HTMLTableCellElement>,
  ) => {
    const { mode } = useContext(context);
    return <StyledTableHeaderCell {...props} ref={forwardedRef} mode={mode} />;
  },
);

export type TableBodyProps = JSX.IntrinsicElements['tbody'];

export const TableBody = forwardRef(
  (
    props: TableBodyProps,
    forwardedRef: React.ForwardedRef<HTMLTableSectionElement>,
  ) => {
    const { mode, singleDataRow } = useContext(context);
    return (
      <StyledTableBody
        {...props}
        ref={forwardedRef}
        mode={mode}
        singleDataRow={singleDataRow}
      />
    );
  },
);

export type TableDataCellProps = JSX.IntrinsicElements['td'];

export const TableDataCell = forwardRef(
  (
    props: TableDataCellProps,
    forwardedRef: React.ForwardedRef<HTMLTableCellElement>,
  ) => {
    const { mode } = useContext(context);
    return <StyledTableDataCell {...props} ref={forwardedRef} mode={mode} />;
  },
);
