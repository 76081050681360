import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  LabelHTMLAttributes,
} from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme';
import { LangDirection } from '../../Menu/types';
import Fieldset from '../Fieldset';
import _Label from '../Label';
import Legend from '../Legend';
import RadioButton from './RadioButton';

interface RadioLabelProps
  extends DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  active: boolean;
  theme: Theme;
  direction: LangDirection;
}

const Label = _Label as any;

const RadioLabel = styled(
  ({ active, theme, direction, ...props }: RadioLabelProps) => (
    <Label {...props} />
  ),
)`
  text-align: center;
  flex-grow: 1;
  transition: background-color 300ms ease-in-out;
  cursor: pointer;

  ${({ active, theme, direction }: RadioLabelProps) => css`
    background-color: ${active ? theme.colors.Brandy : 'transparent'};
    border-${direction === 'ltr' ? 'right' : 'left'}: 1px solid ${
    theme.colors.Brandy
  };

    &:last-child {
      border-${direction === 'ltr' ? 'right' : 'left'}: none;
    }
  `}
`;

type RadioButtonDefaultProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'type' | 'value' | 'checked' | 'defaultChecked'
>;

export type Serializer = (value: any) => string;

export interface RadioButtonGroupItem {
  label?: string;
  value: any;
}

export type Value = string | number;

export interface RadioButtonGroupProps extends RadioButtonDefaultProps {
  legend?: string;
  serializer?: Serializer;
  items: (RadioButtonGroupItem | Value)[];
  value: RadioButtonGroupItem | Value;
  direction?: LangDirection;
  testId?: string;
}

export const defaultSerializer = (value: any) =>
  typeof value === 'string' ? value : JSON.stringify(value);

export interface LabelledRadioButtonProps extends RadioButtonDefaultProps {
  label?: string;
  value: any;
  checked: boolean;
  direction: LangDirection;
}

const LabelledRadioButton = ({
  name,
  label,
  value,
  direction,
  ...props
}: LabelledRadioButtonProps) => {
  return (
    <RadioLabel
      active={props.checked}
      key={value}
      direction={direction}
      data-testid="radio-button"
      data-value={value}
    >
      {label ?? value}
      <RadioButton name={name} value={value} {...(props as any)} />
    </RadioLabel>
  );
};

const isSingleValue = (value: RadioButtonGroupItem | Value) =>
  typeof value === 'string' || Number.isFinite(value);

const RadioButtonGroup = ({
  legend,
  name,
  items,
  value: _groupValue,
  serializer = defaultSerializer,
  direction = 'ltr',
  testId,
  ...props
}: RadioButtonGroupProps) => {
  const groupValue = serializer(_groupValue);
  return (
    <Fieldset data-testid={testId}>
      {legend && <Legend>{legend}</Legend>}
      {items.map((item) => {
        if (isSingleValue(item)) {
          const serializedValue = serializer(item);
          return (
            <LabelledRadioButton
              direction={direction}
              key={serializedValue}
              name={name}
              value={serializedValue}
              checked={serializedValue === groupValue}
              {...props}
            />
          );
        }
        const { value, label } = item as RadioButtonGroupItem;
        const serializedValue = serializer(value);
        return (
          <LabelledRadioButton
            direction={direction}
            key={serializedValue}
            name={name}
            label={label}
            value={serializedValue}
            checked={serializedValue === groupValue}
            {...props}
          />
        );
      })}
    </Fieldset>
  );
};

export default RadioButtonGroup;
