import React from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme';
import {
  DEFAULT_MENU_SIDE_BAR_WIDTH,
  TOP_BAR_MENU_ITEM_GAP,
} from '../constants';
import MenuExpandButton from './MenuExpandButton';
import { MenuItem, RealLinkProps } from '../types';
import useMenuTopBar from './useMenuTopBar';
import LangSelectButton from '../LangSelection/LangSelectButton';
import LangLink from '../LangSelection/LangLink';
import { useMenuLangDir } from '../lang';

interface WrapperProps {
  visible: boolean;
}

const OuterWrapper = styled.nav`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-left: 30px;
  overflow: hidden;
  align-items: center;
  min-width: 50px;

  @media only screen and (min-width: ${DEFAULT_MENU_SIDE_BAR_WIDTH}px) {
    gap: 100px;
  }
`;

const InnerWrapper = styled.ul`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: ${TOP_BAR_MENU_ITEM_GAP}px;
  flex-wrap: nowrap;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  ${({ visible }: WrapperProps) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
  `};
`;

interface MenuTopBarButtonProps extends RealLinkProps {
  theme: Theme;
  active: boolean;
  visible: boolean;
}

const MenuTopBarButton = styled(
  ({ active, visible, className, ...props }: MenuTopBarButtonProps) => (
    <li className={className}>
      <LangLink {...props} />
    </li>
  ),
)`
  white-space: nowrap;
  font-size: 2rem;
  padding: 0 10px;

  ${({ theme, visible }: MenuTopBarButtonProps) => css`
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: border-bottom-color 300ms ease-in-out;
    display: ${visible ? 'block' : 'none'};

    & a {
      text-decoration: none;
      color: ${theme.colors.Cultured};
    }

    ${({ active }: MenuTopBarButtonProps) => {
      if (active) {
        return css`
          border-bottom-color: ${theme.colors.Brandy};
        `;
      }
      return css``;
    }}
  `};
`;

const TopBarLangSelectButton = styled(LangSelectButton)`
  display: none;
  margin-left: 30px;

  @media only screen and (min-width: 900px) {
    display: block;
  }
`;

const MenuTopBar = () => {
  const {
    isTopBarMenuVisible,
    isMenuSidebarOpen,
    wrapperElementRef,
    isMenuItemVisible,
    isSideBarMenuExpandButtonVisible,
    topMenuItems,
    isMenuItemActive,
    enableLangSelection,
    testIdGenerator,
  } = useMenuTopBar();
  const direction = useMenuLangDir();

  return (
    <OuterWrapper data-testid={testIdGenerator('menu-top-bar')}>
      <InnerWrapper
        ref={wrapperElementRef}
        visible={isTopBarMenuVisible}
        style={{
          direction,
          justifyContent: direction === 'ltr' ? 'flex-end' : 'flex-start',
        }}
      >
        {topMenuItems.map(({ label, to }: MenuItem, index: number) => (
          <MenuTopBarButton
            key={label}
            to={to}
            active={isMenuItemActive(to)}
            visible={isMenuItemVisible(index)}
          >
            {label}
          </MenuTopBarButton>
        ))}
      </InnerWrapper>
      {enableLangSelection && !isMenuSidebarOpen && <TopBarLangSelectButton />}
      {isSideBarMenuExpandButtonVisible && <MenuExpandButton />}
    </OuterWrapper>
  );
};

export default MenuTopBar;
