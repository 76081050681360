import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface MenuSideBarListProps extends HTMLAttributes<HTMLUListElement> {
  visible: boolean;
}

const MenuSideBarList = styled(
  ({ visible, ...props }: MenuSideBarListProps) => <ul {...props} />,
)`
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;

  ${({ visible }: MenuSideBarListProps) => {
    return css`
      display: ${visible ? 'grid' : 'none'};
    `;
  }}
`;

export default MenuSideBarList;
