import { sentenceCase, paramCase } from 'change-case';
import {
  parseLocationQS,
  parseQS,
  QSInput,
  stringifyQS,
} from '../universal/data/url';

export const parseHeroesQueryOptions = (init: QSInput) => {
  const instance = parseQS(init);
  const _page = instance.get('page') ?? '1';
  let page = Number.parseInt(String(_page));
  if (!Number.isInteger(page) || page < 1) {
    page = 1;
  }
  const status = instance.get('status') ?? '';
  return {
    page,
    status: sentenceCase(status),
    search: instance.get('search') ?? '',
  };
};

export interface StringifyHeroesQueryOptionsProps {
  page?: number;
  search: string;
  status: string;
}

export const stringifyHeroesQueryOptions = ({
  page,
  search,
  status,
}: StringifyHeroesQueryOptionsProps) =>
  stringifyQS({
    page,
    search,
    status: paramCase(status),
  });

export const parseHeroesOptionsFromLocation = (
  ...params: Parameters<typeof parseLocationQS>
) => parseHeroesQueryOptions(parseLocationQS(...params));

export interface CreateFilterKeyProps {
  search: string;
  status: string;
}

export const createFilterKey = ({ search, status }: CreateFilterKeyProps) =>
  `${search}:${status}`;
