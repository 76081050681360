import React, { ReactElement } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider as _ThemeProvider } from 'styled-components';
import { useWindowSize } from 'usehooks-ts';
import TEXT from '../../../constants/text';
import routes from '../../../data/routes';
import useMenus from '../../../hooks/exclusive/useMenus';
import useReduxLocationSync from '../../../hooks/exclusive/useReduxLocationSync';
import useEntryTrigger from '../../../hooks/universal/useEntryTrigger';
import _GlobalStyles from '../../../styles/exclusive/GlobalStyles';
import theme from '../../../styles/exclusive/theme';
import staticText from '../../../utils/exclusive/staticText';
import { Helmet, HelmetProvider } from '../../universal/Helmet';
import Main from '../../universal/Main';
import MenuProvider from '../../universal/Menu/context/MenuProvider';
import MenuSideBar from '../../universal/Menu/SideBar/MenuSideBar';
import { StaticTextConstants } from '../../universal/StaticText/staticTextContext';
import StaticTextProvider from '../../universal/StaticText/StaticTextProvider';
import Footer from '../page/Footer';
import Header from '../page/Header';

const ThemeProvider = _ThemeProvider as unknown as (props: any) => ReactElement;

const GlobalStyles = _GlobalStyles as unknown as (props: any) => ReactElement;

export interface AppProps {
  helmetContext?: {};
}

const invisibleStyles = `
  body {
    opacity: 0;
    visibility: hidden;
  }
`;

const visibleStyles = `
  body {
    opacity: 1;
    visibility: visible;
  }
`;

const App = ({ helmetContext }: AppProps) => {
  useReduxLocationSync();
  const windowSize = useWindowSize();
  const menuProviderProps = useMenus();
  const routesMatch = useRoutes(routes) as any;
  const [active] = useEntryTrigger(true, {
    timeout: 300,
    activeInSSR: false,
  });

  const lang = menuProviderProps.currentLang?.value as string;

  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider
        theme={{
          ...theme,
          window: windowSize,
        }}
      >
        <MenuProvider {...menuProviderProps}>
          <StaticTextProvider constants={TEXT as StaticTextConstants}>
            <GlobalStyles />
            <Helmet
              defaultTitle={staticText({
                path: 'TITLE',
                lang,
              })}
              titleTemplate={staticText({
                path: 'DEFAULT_TITLE',
                lang,
              })}
              prioritizeSeoTags
            >
              <style>{active ? visibleStyles : invisibleStyles}</style>
              <noscript>{`<style>${visibleStyles}</style>`}</noscript>
            </Helmet>
            <MenuSideBar>
              <Header />
              <Main>{routesMatch}</Main>
              <Footer />
            </MenuSideBar>
          </StaticTextProvider>
        </MenuProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
