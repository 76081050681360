import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchHeroByIdDispatcher } from '../../data/heroes/dispatchers';
import { AppDispatch, RootState } from '../../data/store';
import { ProfileEntry } from '../../utils/exclusive/types';
import useCFEntry from './useCFEntry';

const useProfileData = () => {
  const dispatch = useDispatch() as AppDispatch;
  const { heroId } = useParams();
  const createCfEntry = useCFEntry();

  if (!heroId) {
    return undefined;
  }

  const profile = useSelector<RootState, ProfileEntry>((state) => {
    const { profiles } = state.heroes;
    return profiles[heroId];
  });

  useEffect(() => {
    if (!profile) {
      fetchHeroByIdDispatcher(heroId)(dispatch);
    }
  }, [profile, heroId]);

  if (profile) {
    return createCfEntry(profile);
  }

  return undefined;
};

export default useProfileData;
