import React from 'react';
import { Route } from 'react-router-dom';
import { LANGS } from '../../../constants/lang';
import {
  fetchHeroByIdLoader,
  listHeroesLoader,
} from '../../../data/heroes/loaders';
import { fetchMenusLoader } from '../../../data/menus/loaders';
import { fetchPageByPathLoader } from '../../../data/pages/loaders';
import CMSPage from '../page/CMSPage';
import DefaultLocaleHandler from '../page/DefaultLocaleHandler';
import GenericPage from '../page/GenericPage';
import StaticPage from '../page/StaticPage';
import Profile from '../profiles/Profile';
import ProfileGrid from '../profiles/ProfileGrid';

const routesElement = (
  <Route>
    {Object.keys(LANGS).map((langCode: string) => {
      return (
        <Route
          key={langCode}
          path={`/${langCode}`}
          element={<GenericPage />}
          loader={() => fetchMenusLoader}
        >
          <Route
            path="heroes/:heroId"
            element={<Profile />}
            loader={() => fetchHeroByIdLoader}
          />
          <Route element={<CMSPage />} loader={() => fetchPageByPathLoader}>
            <Route
              path="heroes"
              element={<ProfileGrid />}
              loader={() => listHeroesLoader}
            />
            <Route path="*?" element={<StaticPage />} />
          </Route>
        </Route>
      );
    })}
    <Route path="*" element={<DefaultLocaleHandler />} />
  </Route>
);

export default routesElement;
