import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  extractLangAndAgnosticPath,
  prepLangUrl,
} from '../../components/universal/Menu/lang';
import { SETTINGS_COOKIE } from '../../constants/cookies';
import { DEFAULT_LANG_CODE, LANGS } from '../../constants/lang';
import cookies from '../../utils/universal/cookies';

export interface SettingsStoreState {
  lang: {
    value: string;
    remember: boolean;
  };
}

const getInitialLanguage = () => {
  let value: string = 'en';
  let remember: boolean = false;

  // TODO: handle node using the request object
  if (globalThis.window) {
    const { lang } = extractLangAndAgnosticPath({
      url: prepLangUrl(location),
      langs: LANGS,
    });

    if (lang) {
      value = lang;
    } else if (cookies.get(SETTINGS_COOKIE)?.lang) {
      value = cookies.get(SETTINGS_COOKIE)?.lang.value;
    } else {
      value = navigator?.language.split('-')[0] ?? DEFAULT_LANG_CODE;
    }

    if (!LANGS[value]) {
      value = DEFAULT_LANG_CODE;
    }

    if (cookies.get(SETTINGS_COOKIE)?.lang.remember) {
      remember = true;

      if (cookies.get(SETTINGS_COOKIE)?.lang.value !== value) {
        cookies.set(SETTINGS_COOKIE, {
          lang: {
            value,
            remember,
          },
        });
      }
    }
  }

  return {
    value,
    remember,
  } as SettingsStoreState['lang'];
};

const initialState: SettingsStoreState = {
  lang: getInitialLanguage(),
};

const { reducer, actions } = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLanguageAction: (
      state: SettingsStoreState,
      action: PayloadAction<{ value?: string; remember?: boolean }>,
    ) => {
      state.lang = {
        ...state.lang,
        ...action.payload,
      };
      if (state.lang.remember) {
        cookies.set(SETTINGS_COOKIE, state);
      } else {
        cookies.remove(SETTINGS_COOKIE);
      }
    },
  },
});

export const settingsReducer = reducer;

export const { setLanguageAction } = actions;
