import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from '../../../data/store';
import { Helmet } from '../../universal/Helmet';

const GenericPage = () => {
  const lang = useSelector<RootState, string>(
    (state) => state.settings.lang.value,
  );

  return (
    <>
      <Helmet>
        <html lang={lang} />
      </Helmet>
      <Outlet />
    </>
  );
};

export default GenericPage;
