import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createFilterKey } from '../../utils/exclusive/heroes';
import { ProfileEntry } from '../../utils/exclusive/types';
import { fetchHeroById, listHeroes } from './apis';

export const fetchHeroByIdAction = createAsyncThunk(
  'heroes/fetchHeroById',
  fetchHeroById,
);

export const listHeroesAction = createAsyncThunk(
  'heroes/listHeroes',
  listHeroes,
);

export interface HeroesListResult {
  ids: string[];
  lastFetchedPage: number;
  nextPage?: number;
}

export type HeroesStoreState = {
  profiles: {
    [profileId: string]: ProfileEntry;
  };
  order: {
    [filterKey: string]: {
      filter: {
        status: string;
        search: string;
      };
      list: HeroesListResult;
    };
  };
};

export const defaultListResult: () => HeroesListResult = () => ({
  ids: [],
  nextPage: 1,
  lastFetchedPage: 0,
});

const initialState: HeroesStoreState = {
  profiles: {},
  order: {
    ':': {
      filter: {
        status: '',
        search: '',
      },
      list: defaultListResult(),
    },
  },
};

const { reducer } = createSlice({
  name: 'heroes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHeroByIdAction.fulfilled, (state, action) => {
        const profile = action.payload.item;
        state.profiles[profile.fields.id['en-US']] = profile;
      })
      .addCase(listHeroesAction.fulfilled, (state, action) => {
        const {
          items: profiles,
          nextPage,
          page,
          status,
          search,
        } = action.payload;
        const filterKey = createFilterKey({ status, search });

        if (!(filterKey in state.order)) {
          state.order[filterKey] = {
            filter: {
              status,
              search,
            },
            list: defaultListResult(),
          };
        }

        const { list } = state.order[filterKey];

        if (page > list.lastFetchedPage) {
          profiles.forEach((profile) => {
            state.profiles[profile.fields.id['en-US']] = profile;
            list.ids.push(profile.fields.id['en-US']);
          });
          list.lastFetchedPage = page;
          list.nextPage = nextPage;
        }
      });
  },
});

export const heroesReducer = reducer;
