import React, { ReactNode } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  Document as RichTextDocument,
  BLOCKS,
  MARKS,
  INLINES,
  Block,
  Inline,
} from '@contentful/rich-text-types';
import {
  Document,
  Paragraph,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  UL,
  OL,
  Anchor,
  Underline,
  StyledLink,
} from './NodesAndMarks';
import {
  PageEntryHyperlink,
  PageFields,
  ProfileEntryHyperlink,
  ProfileFields,
} from '../../../utils/exclusive/types';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import { convertNumbersToFarsiCharsInText } from '../../../utils/universal/intl';
import useMenuContext from '../Menu/context/useMenuContext';

export interface RichTextProps {
  document: RichTextDocument;
}

const options = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (node: Block | Inline, children: ReactNode) => (
      <Document>{children}</Document>
    ),
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
      <Paragraph>{children}</Paragraph>
    ),
    [BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => (
      <H1>{children}</H1>
    ),
    [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => (
      <H2>{children}</H2>
    ),
    [BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => (
      <H3>{children}</H3>
    ),
    [BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => (
      <H4>{children}</H4>
    ),
    [BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => (
      <H5>{children}</H5>
    ),
    [BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => (
      <H6>{children}</H6>
    ),
    [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <UL>{children}</UL>
    ),
    [BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => (
      <OL>{children}</OL>
    ),

    [INLINES.ENTRY_HYPERLINK]: (
      node: ProfileEntryHyperlink | PageEntryHyperlink,
      children: ReactNode,
    ) => {
      const entry = new CFEntry<ProfileFields | PageFields>(node.data.target);
      return (
        <StyledLink
          to={
            entry.contentType() === 'page'
              ? entry.field<string>('path')
              : `/heroes/${entry.field<string>('id')}`
          }
        >
          {children}
        </StyledLink>
      );
    },

    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => {
      if (node.data.uri.startsWith('/')) {
        return <StyledLink to={node.data.uri}>{children}</StyledLink>;
      }
      return <Anchor href={node.data.uri}>{children}</Anchor>;
    },
  },
  renderMark: {
    [MARKS.UNDERLINE]: (text: ReactNode) => <Underline>{text}</Underline>,
  },
  renderText: (text: string) => {
    const {
      currentLang: { value: lang },
    } = useMenuContext();
    return lang === 'fa' ? convertNumbersToFarsiCharsInText(text) : text;
  },
};

const RichText = ({ document }: RichTextProps) => (
  <>{documentToReactComponents(document, options as any)}</>
);

export default RichText;
