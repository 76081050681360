import React from 'react';
import useMenuContext from '../../context/useMenuContext';
import { useLangTools } from '../../lang';
import MenuSideBarLink from './MenuSideBarLink';
import MenuSideBarList from './MenuSideBarList';
import MenuSideBarListItem from './MenuSideBarListItem';

export interface MenuSideBarLangSelectionProps {
  isLangMenuActive: boolean;
  onLangChange: (code: string) => () => void;
}

const MenuSideBarLangSelection = ({
  isLangMenuActive,
  onLangChange,
}: MenuSideBarLangSelectionProps) => {
  const { langs, defaultLang, testIdGenerator } = useMenuContext();
  const { extract } = useLangTools();
  const currentUrl = extract().agnosticPath;

  return (
    <MenuSideBarList
      key="language-list"
      visible={isLangMenuActive}
      data-testid={testIdGenerator('sidebar-language-list')}
    >
      {Object.values(langs).map(({ code, label }) => {
        return (
          <MenuSideBarListItem key={code} active={false}>
            <MenuSideBarLink
              to={currentUrl}
              langCode={code}
              onClick={onLangChange(code)}
            >
              {defaultLang ? (
                <>
                  <span lang={defaultLang}>{(label as any)[defaultLang]}</span>
                  {code !== defaultLang && ' - '}
                </>
              ) : null}
              {code !== defaultLang && (
                <span lang={code}>{(label as any)[code]}</span>
              )}
            </MenuSideBarLink>
          </MenuSideBarListItem>
        );
      })}
    </MenuSideBarList>
  );
};

export default MenuSideBarLangSelection;
