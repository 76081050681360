import React from 'react';
import styled, { css } from 'styled-components';
import headerLogo from '../../../media/header-logo.svg';
import { riTestIdGen } from '../../../utils/exclusive/misc';
import LangLink from '../../universal/Menu/LangSelection/LangLink';
import MenuTopBar from '../../universal/Menu/TopBar/MenuTopBar';
import useMenuContext from '../../universal/Menu/context/useMenuContext';

const Spacer = styled.div`
  height: 85px;
`;

const StyledHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.RaisinBlack};
    height: 70px;
    padding: 15px;
    border-bottom: 5px solid ${theme.colors.Brandy};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  `}
`;

const LogoLink = styled(LangLink)`
  ${({ theme }) => css`
    color: ${theme.colors.SpanishGray};
    font-size: 3rem;
    font-weight: 600;
    margin: 5px;
    margin-left: 0;
    text-decoration: none;
    white-space: nowrap;
  `}
`;

const Logo = styled.img`
  display: block;
  max-width: 100%;
  max-height: 55px;
`;

const Header = () => {
  const { mainContentFixedElementWidthOffset } = useMenuContext();

  return (
    <>
      <StyledHeader
        style={{
          width: `calc(100% - ${mainContentFixedElementWidthOffset}px)`,
        }}
      >
        <LogoLink data-testid={riTestIdGen('logo')} to="/">
          <Logo data-testid={riTestIdGen('image')} src={headerLogo} />
        </LogoLink>
        <MenuTopBar />
      </StyledHeader>
      <Spacer>&nbsp;</Spacer>
    </>
  );
};

export default Header;
