import React from 'react';
import styled, { css } from 'styled-components';

export interface PageWrapperProps extends React.HTMLAttributes<HTMLElement> {
  padded?: boolean;
}

const PageWrapper = styled(({ padded = false, ...props }: PageWrapperProps) => (
  <div {...props} />
))`
  display: grid;
  gap: 15px;
  grid-template-rows: 1fr;

  ${({ padded }) =>
    padded &&
    css`
      margin: 0 15px;
    `}
`;

export default PageWrapper;
