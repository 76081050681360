import React from 'react';
import styled, { css } from 'styled-components';
import useFitText from 'use-fit-text';
import { LinkProps } from 'react-router-dom';
import { Theme } from '../../styles/exclusive/theme';
import useImgLoad from '../../hooks/universal/useImgLoad';
import LangLink from './Menu/LangSelection/LangLink';

export interface SectionLinkBackgroundProps extends LinkProps {
  image: string;
}

export const SectionLinkBackground = styled(
  ({ image, ...props }: SectionLinkBackgroundProps) => <LangLink {...props} />,
)`
  ${({ image }) => css`
    display: block;
    position: relative;
    background: url('${image}') no-repeat center center;
    transition: background 300ms ease-in-out;
    background-size: cover;
    min-width: 100vw;
    height: 300px;
    max-width: 100vw;
    font-size: 2rem;
    text-decoration: none;
  `}
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.Brandy};
  transition: all 500ms ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 0.75;
    }
  }
`;

export const SectionLinkText = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  color: ${({ theme }: { theme: Theme }) => theme.colors.Cultured};
  margin: auto;
  width: 300px;
  height: 50px;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
`;

export interface SectionLinkProps {
  image: string;
  link: string;
  text: string;
}

const SectionLink = ({ image, link, text }: SectionLinkProps) => {
  const { fontSize, ref } = useFitText();
  const src = useImgLoad(image);
  return (
    <SectionLinkBackground to={link} image={src}>
      <Overlay />
      <SectionLinkText>{text}</SectionLinkText>
    </SectionLinkBackground>
  );
};

export default SectionLink;
