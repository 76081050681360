import React from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../styles/exclusive/theme';
import Heading from './Heading';

const Header = styled.header`
  ${({ image, theme }: { image: string; theme: Theme }) => css`
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    background: url(${image}) no-repeat center center;
    background-size: cover;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.RaisinBlack};
      opacity: 0.5;
      z-index: 0;
    }

    @media only screen and (min-width: 500px) {
      min-height: 200px;
    }

    @media only screen and (min-width: 800px) {
      min-height: 300px;
    }
  `}
`;

const StyledHeading = styled(Heading)`
  margin: auto;
  padding: 20px;
  z-index: 1;
`;

export interface PageHeaderProps {
  heading: string;
  image: string;
}

const PageHeader = ({ heading, image }: PageHeaderProps) => {
  return (
    <Header image={image}>
      <StyledHeading level={1} size={1}>
        {heading}
      </StyledHeading>
    </Header>
  );
};

export default PageHeader;
