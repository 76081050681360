import { parseISO, format, differenceInYears } from 'date-fns';

export const riTestIdGen = (testId: string) => `ri-${testId}`;

export const formatDate = (
  dateString: string | undefined,
  lang: string,
  unknown: string,
) => {
  if (!dateString) {
    return unknown;
  }

  const date = parseISO(dateString);

  if (lang === 'fa') {
    return new Intl.DateTimeFormat('fa-IR', { dateStyle: 'medium' }).format(
      date,
    );
  }

  return format(date, 'd MMM y');
};

export interface CalculateAgeParams {
  status?: string;
  dateOfBirth?: string;
  dateOfDeath?: string;
  age?: number;
  unknown: string;
}

export const calculateAge = ({
  status,
  dateOfBirth,
  dateOfDeath,
  age,
  unknown,
}: CalculateAgeParams): string => {
  if (dateOfBirth) {
    if (status === 'Dead') {
      if (dateOfDeath) {
        return differenceInYears(
          parseISO(dateOfDeath),
          parseISO(dateOfBirth),
        ).toString();
      }
    } else {
      console.log(new Date());
      return differenceInYears(new Date(), parseISO(dateOfBirth)).toString();
    }
  }

  return age ? age.toString() : unknown;
};
