import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchPageByPathDispatcher } from '../../data/pages/dispatchers';
import { AppDispatch, RootState } from '../../data/store';
import { PageFields } from '../../utils/exclusive/types';
import CFEntry from '../../utils/universal/contentful/CFEntry';
import useCFEntry from './useCFEntry';

const usePageData = () => {
  const dispatch = useDispatch() as AppDispatch;
  const location = useLocation();
  const createCfEntry = useCFEntry();

  let pathname = location.pathname.slice(3); // Remove language code

  if (!pathname) {
    pathname = '/';
  }

  const data = useSelector<RootState, CFEntry<PageFields> | undefined>(
    (state) => {
      const page = state.pages[pathname];

      if (page) {
        return createCfEntry<PageFields>(page);
      }

      return undefined;
    },
  );

  useEffect(() => {
    if (!data) {
      fetchPageByPathDispatcher(pathname)(dispatch);
    }
  }, [location, data]);

  return data;
};

export default usePageData;
