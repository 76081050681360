import BannerCT from './BannerCT';
import LinkGridCT from './LinkGridCT';
import RichTextSectionCT from './RichTextSectionCT';
import SectionLinkCT from './SectionLinkCT';

const contentTypes = {
  sectionLink: SectionLinkCT,
  banner: BannerCT,
  linkGrid: LinkGridCT,
  richTextSection: RichTextSectionCT,
} as unknown as Record<string, (args: Record<string, unknown>) => JSX.Element>;

export default contentTypes;
