import React, { ReactElement } from 'react';
import SectionGenerator from '../../universal/SectionGenerator';
import contentTypes from '../contentTypes';
import usePageData from '../../../hooks/exclusive/usePageData';

const StaticPage = () => {
  const data = usePageData();

  if (!data) {
    return null;
  }

  const content = data.entries('content');
  return (
    <SectionGenerator>
      {
        content.data
          .map((item) => {
            const contentTypeName = item.contentType();
            const contentId = item.contentId();
            const DynamicComponent = contentTypes[contentTypeName];
            if (!DynamicComponent) {
              return null;
            }
            return <DynamicComponent key={contentId} data={item} />;
          })
          .filter((element) => Boolean(element)) as ReactElement[]
      }
    </SectionGenerator>
  );
};

export default StaticPage;
