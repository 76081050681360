import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../../styles/exclusive/theme';
import { ArrowIcon } from '../../../icon-buttons/ArrowButton';
import useMenuContext from '../../context/useMenuContext';

export interface StyledButtonProps {
  theme: Theme;
}

const StyledButton = styled.button`
  display: flex;
  text-decoration: none;
  align-items: center;
  background: none;
  appearance: none;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  text-align: left;
  justify-content: space-between;
`;

const StyledText = styled.div``;

export interface MenuSideBarSubMenuExpandButtonProps
  extends HTMLAttributes<HTMLButtonElement> {
  textPosition: 'before' | 'after';
}

const MenuSideBarSubMenuToggleButton = ({
  children,
  textPosition,
  ...props
}: MenuSideBarSubMenuExpandButtonProps) => {
  const { langs, currentLang } = useMenuContext();
  const { direction } = langs[currentLang.value];
  return (
    <StyledButton {...props}>
      {textPosition === 'before' && (
        <ArrowIcon
          direction={direction === 'ltr' ? 'left' : 'right'}
          size="30px"
        />
      )}
      <StyledText>{children}</StyledText>
      {textPosition === 'after' && (
        <ArrowIcon
          direction={direction === 'ltr' ? 'right' : 'left'}
          size="30px"
        />
      )}
    </StyledButton>
  );
};

export default MenuSideBarSubMenuToggleButton;
