import { Entry } from 'contentful';
import { DefaultFields, ExtendedEntry } from '../../utils/exclusive/types';
import { AvailableLocale } from '../../utils/universal/contentful/CFData';
import CFEntry from '../../utils/universal/contentful/CFEntry';
import useLang from './useLang';

const LOCALES = {
  en: 'en-US',
  fa: 'fa',
} as Record<string, string>;

const useCFEntry = () => {
  const lang = useLang();
  return <Fields extends DefaultFields = DefaultFields>(
    data: ExtendedEntry<string, DefaultFields>,
  ) => {
    return new CFEntry<Fields>(data, {
      locale: LOCALES[lang] as AvailableLocale,
    });
  };
};

export default useCFEntry;
