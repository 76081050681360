import React from 'react';
import LinkScrollReset from '../../LinkScrollReset';
import useMenuContext from '../context/useMenuContext';
import { useLangTools } from '../lang';
import { RealLinkProps } from '../types';

export interface LangLinkProps extends RealLinkProps {
  langCode?: string;
}

const LangLink = ({ to, langCode, ...props }: LangLinkProps) => {
  const { currentLang } = useMenuContext();
  const { prefix } = useLangTools(
    typeof to === 'string'
      ? to
      : {
          pathname: to.pathname ?? '',
          search: to.search ?? '',
          hash: to.hash ?? '',
        },
  );
  return (
    <LinkScrollReset
      to={prefix(langCode ? langCode : currentLang.value)}
      {...props}
    />
  );
};

export default LangLink;
