import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { Theme } from '../../../../../styles/exclusive/theme';
import useMenuContext from '../../context/useMenuContext';
import { LangDirection } from '../../types';
import { useMenuLangDir } from '../../lang';

export interface StyledListItemProps {
  active: boolean;
  direction: LangDirection;
  theme: Theme;
}

const StyledListItem = styled.li`
  white-space: nowrap;

  & > a,
  & > button {
    font-size: 2.5rem;
    cursor: pointer;
    padding: 0 10px;
    width: 100%;
  }

  ${({ theme, direction }: StyledListItemProps) => css`
    direction: ${direction};

    & > a,
    & > button {
      transition: background-color 300ms ease-in-out;
      border: 3px solid ${theme.colors.Brandy};
      border-left: 10px solid ${theme.colors.Brandy};
      border-right: 10px solid ${theme.colors.Brandy};
      color: ${lighten(0.5, theme.colors.Brandy)};
      text-align: ${direction === 'ltr' ? 'left' : 'right'};
    }

    & svg {
      transition: fill 300ms ease-in-out;
      fill: ${lighten(0.5, theme.colors.Brandy)};
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & > a,
        & > button {
          background-color: ${theme.colors.Brandy};
        }
      }
    }

    ${({ active }: StyledListItemProps) => {
      if (active) {
        return css`
          & > a,
          & > button {
            border: 3px solid ${lighten(0.3, theme.colors.VenetianRed)};
            border-left: 10px solid ${lighten(0.3, theme.colors.VenetianRed)};
            border-right: 10px solid ${lighten(0.3, theme.colors.VenetianRed)};
            color: ${lighten(0.5, theme.colors.Brandy)};
          }

          & svg {
            fill: ${lighten(0.5, theme.colors.Brandy)};
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & > a,
              & > button {
                background-color: ${lighten(0.3, theme.colors.VenetianRed)};
                color: ${theme.colors.Brandy};
              }

              & svg {
                fill: ${theme.colors.Brandy};
              }
            }
          }
        `;
      }
      return '';
    }}
  `};
`;

export interface MenuSideBarListItemProps {
  active: boolean;
  children: ReactNode;
}

const MenuSideBarListItem = ({
  children,
  active,
}: MenuSideBarListItemProps) => {
  const direction = useMenuLangDir();
  return (
    <StyledListItem active={active} direction={direction}>
      {children}
    </StyledListItem>
  );
};

export default MenuSideBarListItem;
