import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  MouseEvent,
} from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

const LinkScrollReset = forwardRef(
  ({ onClick: _onClick, ...props }: LinkProps, ref: Ref<HTMLAnchorElement>) => {
    const { to } = props;
    const location = useLocation();
    const { value: shouldScrollUpOnClick, setValue: setShouldScrollUpOnClick } =
      useBoolean(false);

    useEffect(() => {
      const linkPathname =
        typeof to === 'string'
          ? new URL(to, window.location.origin).pathname
          : to.pathname;
      if (linkPathname !== location.pathname) {
        setShouldScrollUpOnClick(true);
      } else {
        setShouldScrollUpOnClick(false);
      }
    }, [to, location.pathname, setShouldScrollUpOnClick]);

    const onClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        _onClick?.(event);
        if (!event.defaultPrevented && shouldScrollUpOnClick) {
          requestAnimationFrame(() => {
            window.scroll(0, 0);
          });
        }
      },
      [shouldScrollUpOnClick, _onClick],
    );

    return <Link {...props} onClick={onClick} ref={ref} />;
  },
);

export default LinkScrollReset;
