import React from 'react';
import { Document as RichTextDocument } from '@contentful/rich-text-types';
import RichText from './RichText';
import FixedWidthWrapper from './FixedWidthWrapper';
import DirectedText from '../exclusive/utils/DirectedText';

export interface RichTextSectionProps {
  document: RichTextDocument;
}

const RichTextSection = ({ document }: RichTextSectionProps) => {
  return (
    <FixedWidthWrapper>
      <DirectedText>
        <RichText document={document} />
      </DirectedText>
    </FixedWidthWrapper>
  );
};

export default RichTextSection;
