import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  parseHeroesOptionsFromLocation,
  stringifyHeroesQueryOptions,
} from '../../utils/exclusive/heroes';
import useLangNavigate from '../../components/universal/Menu/LangSelection/useLangNavigate';

const useProfileGridFilter = () => {
  const location = useLocation();
  const navigate = useLangNavigate();
  const { search: currentSearch, status: currentStatus } =
    parseHeroesOptionsFromLocation(location);
  const [_search, setSearch] = useState(currentSearch);
  const [status, setStatus] = useState(currentStatus);
  const search = useDebounce(_search, 500);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      switch (name) {
        case 'profile-grid-search':
          setSearch(value);
          break;
        case 'profile-grid-status-filter':
          setStatus(value);
          break;
      }
    },
    [location],
  );

  useEffect(() => {
    if (status !== currentStatus) {
      setStatus(currentStatus);
    }

    if (search !== currentSearch) {
      setSearch(currentSearch);
    }
  }, [currentStatus, currentSearch]);

  useEffect(() => {
    if (status !== currentStatus || search !== currentSearch) {
      navigate(
        `/heroes${stringifyHeroesQueryOptions({
          status,
          search,
        })}`,
      );
    }
  }, [status, search]);

  return {
    onChange,
    search: _search,
    status,
  };
};

export default useProfileGridFilter;
