import { ContentfulAsset } from '../../exclusive/types';
import CFData from './CFData';

export default class CFAsset extends CFData<ContentfulAsset, CFAsset> {
  // TODO: use mixins to avoid repetition
  fields<F = ContentfulAsset['fields']>(allLocales: boolean = false): F {
    if (allLocales) {
      return this.data.fields as F;
    }

    return Object.fromEntries(
      Object.entries(this.data.fields).map(([key, value]) => [
        key,
        typeof value[this.options.locale] !== 'undefined'
          ? value[this.options.locale]
          : value['en-US'],
      ]),
    ) as F;
  }

  field<Field>(fieldName: string | number, allLocales: boolean = false): Field {
    const path = ['fields', fieldName];

    if (!allLocales) {
      path.push(this.options.locale);
    }

    let value = this.get(path);

    if (typeof value === 'undefined' && this.options.locale !== 'en-US') {
      path.pop();
      path.push('en-US');
      value = this.get(path);
    }

    return value as Field;
  }

  assetId(): string {
    return this.get('sys.id');
  }

  title() {
    return this.field<string>('title');
  }

  description() {
    return this.field<string>('description') ?? '';
  }

  alt() {
    return this.description() || this.title();
  }

  file() {
    return this.field<ContentfulAsset['fields']['file']['en-US']>('file');
  }
}
