// Source: https://coolors.co/272727-7f3535-f5f5f5-959595-c62216

const colors = {
  RaisinBlack: '#272727',
  SpanishGray: '#959595',
  Cultured: '#F5F5F5',
  Brandy: '#7F3535',
  VenetianRed: '#C62216',
};

export type Colors = typeof colors;

export default colors;
