import { LoaderProps } from '../types';
import { fetchPageByPathDispatcher } from './dispatchers';

export const fetchPageByPathLoader = ({
  location: { pathname },
}: LoaderProps) => {
  pathname = pathname.slice(3); // Remove language code

  if (!pathname) {
    pathname = '/';
  }

  return fetchPageByPathDispatcher(pathname);
};
