import React from 'react';
import { BannerFields } from '../../../utils/exclusive/types';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import Banner from '../../universal/Banner';
import HomeBannerText from '../banner/HomeBannerText';

export interface BannerCTProps {
  data: CFEntry<BannerFields>;
}

const BannerCT = ({ data }: BannerCTProps) => {
  return (
    <Banner headingLevel={1} image={data.asset('image').file().url}>
      <HomeBannerText text={data.field('text')} />
    </Banner>
  );
};

export default BannerCT;
