import React from 'react';
import styled, { css } from 'styled-components';
import { LANGS } from '../../../constants/lang';
import { Theme } from '../../../styles/exclusive/theme';
import { riTestIdGen } from '../../../utils/exclusive/misc';
import LinkScrollReset from '../../universal/LinkScrollReset';
import useMenuContext from '../../universal/Menu/context/useMenuContext';
import StaticText from '../../universal/StaticText/StaticText';
import DirectedText from '../utils/DirectedText';

const StyledFooter = styled.footer`
  ${({ theme }: { theme: Theme }) => {
    return css`
      display: flex;
      align-items: center;
      background-color: ${theme.colors.RaisinBlack};
      width: 100%;
      height: 50px;
      padding: 15px;
      border-top: 5px solid ${theme.colors.Brandy};
      max-width: 100vw;
      position: absolute;
      bottom: 0;
    `;
  }}
`;

const Text = styled(DirectedText)`
  ${({ theme }: { theme: Theme }) => {
    return css`
      color: ${theme.colors.SpanishGray};
      font-size: 1rem;
      font-weight: 400;
      margin: auto;

      @media only screen and (max-width: 599px) {
        margin-right: 0;
      }
    `;
  }}
`;

const LangLink = styled(LinkScrollReset)`
  ${({ theme }: { theme: Theme }) => {
    return css`
      color: ${theme.colors.VenetianRed};
      text-decoration: none;
      margin-right: 10px;
    `;
  }}
`;

const Footer = () => {
  const { mainContentFixedElementWidthOffset, currentLang } = useMenuContext();
  const englishLabel = LANGS['en'].label[currentLang.value];
  const farsiLabel = LANGS['fa'].label[currentLang.value];

  return (
    <StyledFooter
      style={{ width: `calc(100% - ${mainContentFixedElementWidthOffset}px)` }}
    >
      <LangLink to="/en">{englishLabel}</LangLink>
      <LangLink to="/fa">{farsiLabel}</LangLink>
      <Text data-testid={riTestIdGen('footer-text')}>
        <StaticText path="FOOTER.CENTER_TEXT" />
      </Text>
    </StyledFooter>
  );
};

export default Footer;
