import { useLocation } from 'react-router-dom';
import useMenuContext from './context/useMenuContext';
import { Langs } from './types';

export interface LangExtractionParams {
  url: string;
  langs: Langs;
}

export interface LangExtractionResult {
  lang?: string;
  agnosticPath: string;
}

export const extractLangAndAgnosticPath = ({
  url,
  langs,
}: LangExtractionParams): LangExtractionResult => {
  const matches = /^\/([a-z]{2})(\/.*)?$/gm.exec(url);
  let lang: string | undefined;
  let agnosticPath = url;

  if (matches && langs[matches[1]]) {
    lang = matches[1];
    agnosticPath = matches[2] && matches[2] !== '/' ? matches[2] : '';
  }

  return {
    lang,
    agnosticPath,
  };
};

export const prepLangUrl = ({
  pathname,
  search,
  hash,
}: {
  pathname: string;
  search: string;
  hash: string;
}) => pathname + search + hash;

export interface LangPrefixingParams {
  url: string;
  langs: Langs;
  langToPrefix: string;
}

export const prefixLang = ({
  url,
  langs,
  langToPrefix,
}: LangPrefixingParams): string => {
  const { agnosticPath } = extractLangAndAgnosticPath({ url, langs });
  return `/${langToPrefix}${agnosticPath}`;
};

export const useLangTools = (
  input?: string | { pathname: string; search: string; hash: string },
) => {
  const location = useLocation();
  const { langs, currentLang } = useMenuContext();
  let url!: string;

  if (input) {
    url = typeof input === 'string' ? input : prepLangUrl(input);
  } else {
    url = prepLangUrl(location);
  }

  return {
    extract: () => extractLangAndAgnosticPath({ url, langs }),
    prefix: (langToPrefix: string = currentLang.value) =>
      prefixLang({ url, langs, langToPrefix }),
  };
};

export const useMenuLangDir = () => {
  const { langs, currentLang } = useMenuContext();
  return langs[currentLang.value].direction;
};
