import React from 'react';

export interface HomeBannerTextProps {
  text: string;
}

const HomeBannerText = ({ text }: HomeBannerTextProps) => {
  const words = text.split(' ');
  if (words.length < 3) {
    return null;
  }
  return (
    <>
      <div style={{ color: '#239f40' }}>{words[0]}</div>
      <div style={{ color: '#FFFFFF' }}>{words[1]}</div>
      <div style={{ color: '#da0000' }}>{words[2]}</div>
    </>
  );
};

export default HomeBannerText;
