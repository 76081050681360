import { fetchHeroByIdAction, listHeroesAction } from './reducer';
import { AppDispatch } from '../store';
import { FetchHeroesByPageParams } from './apis';

export const fetchHeroByIdDispatcher =
  (heroId: string) => (dispatch: AppDispatch) =>
    dispatch(fetchHeroByIdAction(heroId));

export const listHeroesDispatcher =
  (params: FetchHeroesByPageParams) => (dispatch: AppDispatch) =>
    dispatch(listHeroesAction(params));
