import styled, { css } from 'styled-components';
import { Theme } from '../../../styles/exclusive/theme';

const TextInput = styled.input.attrs({ type: 'text' })`
  background: none;
  border: none;
  outline: none;
  font-size: 1.5rem;
  padding: 5px 0;
  border-radius: 0;

  ${({ theme }: { theme: Theme }) => css`
    color: ${theme.colors.Cultured};
    border-bottom: 1px solid ${theme.colors.Brandy};

    &::placeholder {
      color: ${theme.colors.SpanishGray};
    }

    &:focus {
      border-bottom: 1px solid ${theme.colors.VenetianRed};
    }
  `}
`;

export default TextInput;
