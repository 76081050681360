import React from 'react';
import {
  LinkGridFields,
  LinkGridItemFields,
  PageFields,
} from '../../../utils/exclusive/types';
import CFEntry from '../../../utils/universal/contentful/CFEntry';
import LinkGrid from '../../universal/LinkGrid';

export interface LinkGridCTProps {
  data: CFEntry<LinkGridFields>;
}

const processGridItemFields = (entry: CFEntry<LinkGridItemFields>) => {
  return {
    heading: entry.field<string>('heading') ?? '',
    subheading: entry.field<string | undefined>('subheading'),
    image: entry.asset('image')?.file()?.url ?? '',
    link: entry.entry<PageFields>('link')?.field<string>('path') ?? '',
  };
};

const LinkGridCT = ({ data }: LinkGridCTProps) => {
  const items = data.entries<LinkGridItemFields>('items');
  const [main, side, footer] = items.data;

  if ([main, side, footer].some((item) => !item)) {
    return null;
  }

  const linkGridProps = {
    main: processGridItemFields(main),
    side: processGridItemFields(side),
    footer: processGridItemFields(footer),
  };

  return <LinkGrid {...linkGridProps} />;
};

export default LinkGridCT;
