import colors from './colors';

const theme = {
  colors,
  window: {
    width: -1,
    height: -1,
  },
};

export type Theme = typeof theme;

export default theme;
