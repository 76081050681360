import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import useFitText from 'use-fit-text';
import useImgLoad from '../../hooks/universal/useImgLoad';
import { HeadingRange } from './Heading';

export interface BannerBackgroundProps
  extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
}

export const BannerBackground = styled(
  ({ image, ...props }: BannerBackgroundProps) => <div {...props} />,
)`
  ${({ image }) => css`
    background: url('${image}') no-repeat center center;
    transition: background 300ms ease-in-out;
    background-size: cover;
    min-width: 100vw;
    min-height: 300px;
    max-width: 100vw;
    font-size: 15rem;

    @media only screen and (min-width: 300px) {
      min-height: 400px;
    }

    @media only screen and (min-width: 400px) {
      min-height: 500px;
    }

    @media only screen and (min-width: 500px) {
      min-height: 600px;
    }

    @media only screen and (min-width: 600px) {
      min-height: 700px;
    }

    @media only screen and (min-width: 700px) {
      min-height: 800px;
    }
  `}
`;

export interface BannerProps {
  image: string;
  headingLevel: HeadingRange;
  children?: ReactNode;
}

const Banner = ({ image, headingLevel, children }: BannerProps) => {
  const src = useImgLoad(image);
  const { fontSize, ref } = useFitText();
  const DynamicHeading = `h${headingLevel}`;
  return (
    <BannerBackground image={src}>
      {/* @ts-ignore */}
      <DynamicHeading
        ref={ref}
        style={{ fontSize, padding: '20px', margin: 0 }}
      >
        {children}
      </DynamicHeading>
    </BannerBackground>
  );
};

export default Banner;
