import styled, { css } from 'styled-components';
import { Theme } from '../../../styles/exclusive/theme';
import LangLink from '../Menu/LangSelection/LangLink';

const sharedButtonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 2rem;
  cursor: pointer;
  ${({ theme }: { theme: Theme }) => {
    return css`
      color: ${theme.colors.Cultured};
      border: 1px solid ${theme.colors.Brandy};
      padding: 10px 20px;
      transition: color, background-color 300ms ease-in-out;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: ${theme.colors.Brandy};
        }
      }
    `;
  }}
`;

const sharedAnchorAndLinkStyles = css`
  text-decoration: none;
`;

export const Button = styled.button.attrs({
  type: 'button',
})`
  ${sharedButtonStyles}
  background: none;
`;

export const AnchorButton = styled.a`
  ${sharedButtonStyles}
  ${sharedAnchorAndLinkStyles}
`;

export const LinkButton = styled(LangLink)`
  ${sharedButtonStyles}
  ${sharedAnchorAndLinkStyles}
`;
