import React, { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../styles/exclusive/theme';

export interface IconButtonMainProps {
  size?: number;
  fontSize?: string;
  colorMode?: 'primary' | 'secondary';
  scaleOnHover?: boolean;
  text?: ReactNode;
  textPosition?: 'before' | 'after';
}

export interface IconButtonChildrenProps extends IconButtonMainProps {
  actualColor: string;
}

export interface IconButtonProps
  extends Omit<React.ButtonHTMLAttributes<Element>, 'children'>,
    IconButtonMainProps {
  children: (props: IconButtonChildrenProps) => ReactNode;
}

export const lookupIconButtonColor = (colorMode: 'primary' | 'secondary') =>
  ({
    primary: theme.colors.Cultured,
    secondary: theme.colors.VenetianRed,
  }[colorMode]);

const StyledButton = styled.button`
  display: flex;
  background: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  transition: all 300ms ease-in-out;
  gap: 10px;
  align-items: center;

  ${({ size, scaleOnHover }: Required<IconButtonProps>) => {
    return css`
      & svg {
        width: ${size}px;
        height: ${size}px;
      }

      ${scaleOnHover
        ? css`
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                transform: scale(1.1, 1.1);
              }
            }
          `
        : ''}
    `;
  }}
`;

interface IconButtonTextProps extends HTMLAttributes<HTMLDivElement> {
  actualColor: string;
  fontSize: string;
}

const IconButtonText = styled(
  ({ actualColor, fontSize, ...props }: IconButtonTextProps) => (
    <div {...props} />
  ),
)`
  ${({ actualColor, fontSize }: IconButtonTextProps) => {
    return css`
      color: ${actualColor};
      font-size: ${fontSize};
    `;
  }}
`;

const IconButton = ({
  children,
  size,
  colorMode,
  scaleOnHover,
  text,
  textPosition = 'before',
  fontSize = '3rem',
  ...props
}: IconButtonProps) => {
  const mainProps = {
    size: size ?? 30,
    colorMode: colorMode ?? 'primary',
    scaleOnHover: scaleOnHover ?? false,
  };
  const actualColor = lookupIconButtonColor(mainProps.colorMode);
  const childrenProps = {
    ...mainProps,
    actualColor,
  };
  const textNode = text ? (
    <IconButtonText actualColor={actualColor} fontSize={fontSize}>
      {text}
    </IconButtonText>
  ) : null;
  return (
    <StyledButton type="button" {...mainProps} {...(props as any)}>
      {textPosition === 'before' && textNode}
      {children(childrenProps)}
      {textPosition === 'after' && textNode}
    </StyledButton>
  );
};

export default IconButton;
