import styled from 'styled-components';

const Main = styled.main`
  display: grid;
  gap: 15px;
  grid-template-rows: 1fr;
  padding-bottom: 65px;
`;

export default Main;
