import styled, { css } from 'styled-components';
import { Theme } from '../../../styles/exclusive/theme';

const Fieldset = styled.fieldset`
  ${({ theme }: { theme: Theme }) => css`
    display: flex;
    border: 1px solid ${theme.colors.Brandy};
    margin: 0;
    padding: 0;

    &:focus {
      border: 1px solid ${theme.colors.VenetianRed};
    }
  `}
`;

export default Fieldset;
