import React, { ReactElement } from 'react';
import styled from 'styled-components';
import useMeasure from 'react-use-measure';

export interface ImageGridProps {
  children: ReactElement | ReactElement[];
  minColumnWidth: number;
  gap: number;
  testId?: string;
}

const Wrapper = styled.div`
  display: grid;
`;

export interface CalculateColumnCountParams {
  containerWidth: number;
  minColumnWidth: number;
  gap: number;
}

const calculateColumnCount = ({
  containerWidth,
  minColumnWidth,
  gap,
}: CalculateColumnCountParams) => {
  if (containerWidth < minColumnWidth * 2 + gap) {
    return 1;
  }

  const columnWidthWithGap = minColumnWidth + gap;
  const columnCount = Math.floor(containerWidth / columnWidthWithGap);
  return columnCount;
};

const genTemplateCols = (columnCount: number) => {
  return new Array(columnCount).fill('1fr').join(' ');
};

const ImageGrid = ({
  children,
  minColumnWidth,
  gap,
  testId,
}: ImageGridProps) => {
  const [elRef, { width: containerWidth }] = useMeasure();
  const columnCount = calculateColumnCount({
    containerWidth,
    minColumnWidth,
    gap,
  });
  const templateCols = genTemplateCols(columnCount);

  return (
    <Wrapper
      ref={elRef}
      data-testid={testId}
      style={{
        gap,
        gridTemplateColumns: templateCols,
      }}
    >
      {children}
    </Wrapper>
  );
};

export default ImageGrid;
