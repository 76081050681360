import { configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { errorReducer } from './error/reducer';
import { heroesReducer } from './heroes/reducer';
import { menusReducer } from './menus/reducer';
import { pagesReducer } from './pages/reducer';
import { routerReducer } from './router/reducer';
import { settingsReducer } from './settings/reducer';

const baseStoreConfig = {
  reducer: {
    error: errorReducer,
    menus: menusReducer,
    heroes: heroesReducer,
    pages: pagesReducer,
    settings: settingsReducer,
    router: routerReducer,
  },
};

export const store = configureStore({
  ...baseStoreConfig,
  // TODO: the cypress check is a temp solution to allow mocking APIs. Need a better solution.
  preloadedState: (globalThis as any).Cypress
    ? undefined
    : (globalThis as Record<string, any>).__PRELOADED_STATE__,
});

delete (globalThis as Record<string, any>).__PRELOADED_STATE__;

export const generateStore = (
  preloadedState?: RootState,
): ToolkitStore<RootState> => {
  return configureStore({
    ...baseStoreConfig,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
