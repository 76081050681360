import { get, Path } from 'object-path';
import {
  StaticTextConstants,
  StaticTextGenerator,
  StaticTextGeneratorParams,
  StaticTextGeneratorResult,
} from './staticTextContext';

export interface StaticTextExtractorParams {
  constants: StaticTextConstants;
  path: Path;
  params?: StaticTextGeneratorParams;
  lang: string;
}

const staticTextExtractor = ({
  constants,
  path,
  params,
  lang,
}: StaticTextExtractorParams): string => {
  const generator = get(constants, path) as
    | StaticTextGenerator
    | StaticTextGeneratorResult;

  if (typeof generator === 'function') {
    return generator(params)[lang];
  } else if (typeof generator === 'object' && generator) {
    return generator[lang];
  }

  return '';
};

export default staticTextExtractor;
