import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../../../styles/exclusive/theme';
import useMenuContext from '../context/useMenuContext';

export interface StyledButtonProps extends HTMLAttributes<HTMLButtonElement> {
  active: boolean;
  theme: Theme;
}

const StyledButton = styled(
  ({ active, theme, ...props }: StyledButtonProps) => <button {...props} />,
)`
  display: block;
  border: none;
  background: none;
  appearance: none;
  cursor: pointer;
  padding: 0 10px;
  font-size: 1.3em;
  height: 40px;
  white-space: nowrap;
  transition: background-color 300ms ease-in-out;

  ${({ theme }: StyledButtonProps) => css`
    color: ${theme.colors.Cultured};
    border: 1px solid ${theme.colors.Brandy};

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: ${theme.colors.Brandy};
      }
    }
  `}

  ${({ active, theme }: StyledButtonProps) => {
    if (active) {
      return css`
        background-color: ${theme.colors.Brandy};
      `;
    }
    return '';
  }}
`;

export interface LangSelectButtonProps
  extends Omit<HTMLAttributes<HTMLButtonElement>, 'children'> {
  active?: boolean;
}

const LangSelectButton = ({
  active = false,
  ...props
}: LangSelectButtonProps) => {
  const {
    enableLangMenu,
    toggleMenuSidebarOpen,
    menuExpandButtonLabel,
    testIdGenerator,
  } = useMenuContext();
  const onClick = () => {
    toggleMenuSidebarOpen();
    enableLangMenu();
  };
  return (
    <StyledButton
      data-testid={testIdGenerator('lang-select-button')}
      active={active}
      onClick={onClick}
      {...props}
    >
      {menuExpandButtonLabel}
    </StyledButton>
  );
};

export default LangSelectButton;
