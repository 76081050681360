import { createContext } from 'react';

export type StaticTextGeneratorParams = Record<string, any>;

export interface StaticTextGeneratorResult {
  [lang: string]: string;
}

export type StaticTextGenerator = (
  params?: StaticTextGeneratorParams,
) => StaticTextGeneratorResult;

export interface StaticTextConstants {
  [key: string]:
    | StaticTextConstants
    | StaticTextGenerator
    | StaticTextGeneratorResult;
}

export interface StaticTextContext {
  constants: StaticTextConstants;
  lang: string;
}

export default createContext<StaticTextContext>({
  constants: {},
  lang: '',
});
