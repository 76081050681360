import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { listHeroesDispatcher } from '../../data/heroes/dispatchers';
import { defaultListResult } from '../../data/heroes/reducer';
import { AppDispatch, RootState } from '../../data/store';
import {
  createFilterKey,
  parseHeroesOptionsFromLocation,
} from '../../utils/exclusive/heroes';
import { ProfileEntry } from '../../utils/exclusive/types';
import useCFEntry from './useCFEntry';

const PAGE_LIMIT = 10;

interface SelectorResult {
  lastFetchedPage: number;
  nextPage?: number;
  profiles: ProfileEntry[];
}

const useProfileListData = () => {
  const location = useLocation();
  const { page, search, status } = parseHeroesOptionsFromLocation(location);
  const dispatch = useDispatch() as AppDispatch;
  const createCfEntry = useCFEntry();
  const { profiles, lastFetchedPage, nextPage } = useSelector<
    RootState,
    SelectorResult
  >((state) => {
    const { profiles, order } = state.heroes;
    const filterKey = createFilterKey({ status, search });
    const { ids, lastFetchedPage, nextPage } =
      order[filterKey]?.list ?? defaultListResult();

    return {
      profiles: ids.map((id) => profiles[id]),
      lastFetchedPage,
      nextPage,
    };
  });

  useEffect(() => {
    if (page > lastFetchedPage && nextPage) {
      listHeroesDispatcher({
        startPageNo: lastFetchedPage + 1,
        endPageNo: page,
        status,
        search,
      })(dispatch);
    }
  }, [page, lastFetchedPage, nextPage, status, search, dispatch]);

  const endPage = page < lastFetchedPage ? page : lastFetchedPage;
  const endIndex = endPage * PAGE_LIMIT;

  return {
    page,
    profiles: profiles
      .slice(0, endIndex)
      .map((profile) => createCfEntry(profile)),
    nextPage: page < lastFetchedPage ? page + 1 : nextPage,
    status,
    search,
  };
};

export default useProfileListData;
