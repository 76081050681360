import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import LabelledSwitch from '../../../Form/Switch/LabelledSwitch';
import useMenuContext from '../../context/useMenuContext';
import { ParentMenuItem, SideBarMenuParentNode } from '../../types';
import MenuSideBarHeader from './MenuSideBarHeader';
import MenuSideBarItems from './MenuSideBarItems';
import MenuSideBarLangSelection from './MenuSideBarLangSelection';

const Wrapper = styled.div`
  display: grid;
  gap: 30px;
  padding: 15px 20px;
`;

const Nav = styled.nav`
  display: grid;
  gap: 20px;
`;

const MenuSideBarContent = () => {
  const { current: root } = useRef<SideBarMenuParentNode>({ id: 'Root' });
  const {
    toggleMenuSidebarOpen,
    isLangMenuActive,
    toggleLangMenu,
    disableLangMenu,
    enableLangSelection,
    currentLang,
    setCurrentLang,
    sideMenuGoBackButtonLabel,
    testIdGenerator,
  } = useMenuContext();
  const [parentPath, setParentPath] = useState<SideBarMenuParentNode[]>([root]);

  const onChangeRememberLanguageSelection = useCallback(
    ({ checked }: { checked: boolean }) => {
      setCurrentLang({
        remember: checked,
      });
    },
    [],
  );

  const resetMenu = () => setParentPath([root]);

  const onClose = () => {
    toggleMenuSidebarOpen();
    disableLangMenu();
    resetMenu();
  };

  const onLangChange = () => () => {
    onClose();
  };

  return (
    <Wrapper>
      <MenuSideBarHeader
        {...{
          isLangMenuActive,
          toggleLangMenu,
          onClose,
          showBackButton:
            !isLangMenuActive && parentPath[parentPath.length - 1] !== root,
          onBackButtonClick: () => {
            setParentPath(parentPath.slice(0, parentPath.length - 1));
          },
          hasLangSelectButton: enableLangSelection,
          sideMenuGoBackButtonLabel,
          testIdGenerator,
        }}
      />
      <Nav>
        <MenuSideBarItems
          {...{
            root,
            onClose,
            resetMenu,
            openSubMenu: (item: ParentMenuItem) => () =>
              setParentPath([...parentPath, item]),
            isItemListVisible: (parent: SideBarMenuParentNode) =>
              isLangMenuActive
                ? false
                : parentPath[parentPath.length - 1] === parent,
          }}
        />
        <div style={{ display: isLangMenuActive ? 'block' : 'none' }}>
          <LabelledSwitch
            testId={testIdGenerator('sidebar-remember-lang-selection')}
            label="Remember language selection"
            value={currentLang.remember}
            onChange={onChangeRememberLanguageSelection}
          />
        </div>
        <MenuSideBarLangSelection
          {...{
            isLangMenuActive,
            onLangChange,
          }}
        />
      </Nav>
    </Wrapper>
  );
};

export default MenuSideBarContent;
